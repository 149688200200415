import { User } from 'store/user'
import { Status, usePromiseState } from '../usePromiseState'
import { useUserStore } from './useUserStore'

export function useSelfUser(): [Status, User | undefined, () => void] {
    const store = useUserStore()
    const [status, setStatus] = usePromiseState(() => store.getOrLoadSelfUser())

    const refresh = () => {
        setStatus(store.loadSelf())
    }

    return [status, store.selfUser, refresh]
}
