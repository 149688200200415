import { Entitlement, Usage } from '@kibsi/ks-entitlement-types'
import { AxiosRequestConfig } from 'axios'
import { KibsiClient } from 'client'
import { KibsiClientParams } from './base'

export class EntitlementKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'entitlement')
    }

    getEntitlements(requestConfig?: AxiosRequestConfig): Promise<Entitlement[]> {
        return this.get(`/entitlements`, {
            ...requestConfig,
        })
    }

    listFeatureUsage(requestConfig?: AxiosRequestConfig): Promise<Usage[]> {
        return this.get(`/usage`, {
            ...requestConfig,
        })
    }

    getFeatureUsage(featureId: string, requestConfig?: AxiosRequestConfig): Promise<Usage> {
        return this.get(`/usage/${featureId}`, {
            ...requestConfig,
        })
    }
}
