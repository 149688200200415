import { io, ManagerOptions, Socket } from 'socket.io-client'
import * as msgpackParser from 'socket.io-msgpack-parser'
import { VdbEndpoint } from '@kibsi/ks-deployment-types'
import logger from '../../logging/logger'
import { FrameStore } from './frame.store'
import { DeploymentInfo, Frame } from './video-stream.type'

export class VideoStreamClient {
    private socket?: Socket
    deploymentInstanceId: string
    wsAddress: string

    constructor(deploymentInfo: DeploymentInfo, endpoint: VdbEndpoint, private store: FrameStore) {
        this.deploymentInstanceId = `${deploymentInfo.deploymentId}/${deploymentInfo.instanceId}`
        this.wsAddress = `wss://${endpoint.hostname}/stream?_t=${endpoint.token}`
    }

    start(): Promise<void> {
        logger.log('starting lvs client')
        return new Promise((resolve, reject) => {
            const options: Partial<ManagerOptions> = {
                transports: ['websocket'],
                parser: msgpackParser,
            }

            const socket = io(this.wsAddress, options)

            socket.on('connect', () => {
                logger.log(`socket ${socket.id} connected @ ${this.wsAddress}`)
                resolve()
            })

            socket.on('disconnect', (reason) => {
                logger.log(`socket disconnected @ ${this.wsAddress}, reason: ${reason}`)
            })

            socket.on('connect_error', (e) => {
                logger.log(`failed to connect @ ${this.wsAddress}}`)
                reject(e)
            })

            socket.on(`FRAME:${this.deploymentInstanceId}`, (r: Frame | string) => this.onFrameResponse(r))

            this.socket = socket
        })
    }

    private onFrameResponse(response: Frame | string) {
        if (typeof response === 'string') {
            logger.log(`got EOS - done`)
            return
        }

        const { scene } = response

        logger.log(`got a frame - ${scene?.ats}`)

        if (scene?.image === undefined || scene?.appState === undefined || scene?.objects === undefined) {
            logger.warn(`frame ${scene.ats} is incomplete and will be skipped`)
            return
        }

        this.store.state = { frame: response }
    }

    public subscribe(): void {
        if (!this.socket) {
            logger.error('socket not started')
        } else {
            logger.log(`subscribing to 'subscribeDeployment' : ${this.deploymentInstanceId}`)
            this.socket.emit('subscribeDeployment', this.deploymentInstanceId)
        }
    }

    public unsubscribe(): void {
        if (!this.socket) {
            logger.error('socket not started')
        } else {
            this.socket.emit('unsubscribeDeployment', this.deploymentInstanceId)
            this.socket.close()
        }
    }
}
