import { PaginationRequest, SiteKibsiClient } from '@kibsi/ks-client-sdk'
import { Site, SiteCreate, SiteCriteria, SiteUpdate } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { SiteService } from './site.service'

export type SiteApi = Pick<InstanceType<typeof SiteKibsiClient>, 'create' | 'delete' | 'list' | 'read' | 'update'>

@injectable()
export class SiteServiceImpl implements SiteService {
    constructor(@inject(TYPES.RequestStore) private req: RequestStore, @inject(TYPES.SiteApi) private api: SiteApi) {}

    list(request?: PaginationRequest<SiteCriteria>): Promise<Site[]> {
        return this.req.once('site.list', async () => {
            const { results } = await this.api.list(request)

            return results
        })
    }

    get(id: string): Promise<Site> {
        return this.req.once(`site.get.${id}`, () => this.api.read(id))
    }

    create(site: SiteCreate): Promise<Site> {
        return this.api.create(site)
    }

    update(site: SiteUpdate): Promise<void> {
        return this.api.update(site)
    }

    delete(siteId: string, cascade?: boolean): Promise<void> {
        return this.api.delete(siteId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }
}
