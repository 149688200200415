import type { Optional } from '@kibsi/common'
import type { Application as AppDto, EventItemType, GenerationType, ItemType } from '@kibsi/ks-application-types'
import type {
    Audit,
    Deployment as UpdateDto,
    DeploymentStatus,
    DeploymentStatusReason,
    DeploymentUpdate,
    EnhancerAttr,
    RegionAttachment,
    RegionAttr,
    StaticItem,
    VdbEndpoint,
    DetectableItemConfig,
    DetectorTracker,
    DetectableItemConfigOptions,
} from '@kibsi/ks-deployment-types'
import type { Site as SiteDto } from '@kibsi/ks-tenant-types'
import type {
    AppRef,
    Deployment as GetDto,
    DeploymentDetails as ListDto,
    SiteRef,
    VersionRef,
} from '@kibsi/ks-ui-types'
import log from 'logging/logger'
import { comparer, makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { nanoid } from 'nanoid'
import { AttrValue } from 'pages/deployments/config/static-item/types'
import { parseErrorMessage } from 'util/error'
import { fromPromise } from 'util/mobx'
import type { DeploymentService } from '../../service'
import type { FromDto, ToDto } from '../interfaces'
import { initStatus } from '../utils'
import { AppVersionDto } from '../app'
import { getRegions } from '../../pages/deployments/config/static-item/helper'
import { ItemPolygon } from '../../components/draw'

export type AnyDto = GetDto | ListDto

export type Dto = Optional<UpdateDto, 'deploymentDefinition'>

export type BulkAddStaticItemsType = { [itemTypeId: string]: number | undefined | null }

type DeploymentEditable = Omit<DeploymentUpdate, 'deploymentId'>

export type UpdateAppVersionUpdateStatus = 'success' | 'approvalNeeded' | 'uninitialized'

export type UpdateAppVersionResult = {
    status: UpdateAppVersionUpdateStatus
    appVersion: AppVersionDto
    staticItemsToDelete?: string[]
    // add more validation results here
}

export type LaunchTargetType = 'Kibsi_SAAS' | 'Edge' | 'Customer_Cloud'
export type LaunchTarget = {
    type: LaunchTargetType
    edgeDeviceId?: string
}

export type DetectableItemConfigUpdate = {
    minScore?: number
    tracker?: DetectorTracker
}

export class Deployment implements ToDto<Dto>, FromDto<Dto> {
    private reactions: IDisposer[] = []
    private skipUpdate = false

    readonly deploymentId: string

    status = initStatus()

    app?: AppDto
    version?: AppVersionDto
    site?: SiteDto
    vdbEndpoint?: VdbEndpoint

    constructor(
        private dto: Dto,
        private service: DeploymentService,
        private updateDelay: number = 0, // this parameter is the delay to the reactions before calling update
    ) {
        this.deploymentId = dto.deploymentId

        makeAutoObservable<Deployment, 'service' | 'reactions'>(this, {
            deploymentId: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    /**
     * returns if this deployment mobx object is editable. e.g. if the deploymentDefinition is included.
     *
     */
    isEditable(): boolean {
        return !!this.dto.deploymentDefinition
    }

    get name(): string {
        return this.deploymentName
    }

    get deploymentName(): string {
        return this.dto.deploymentName
    }

    set deploymentName(deploymentName: string) {
        this.dto.deploymentName = deploymentName
    }

    get description(): string | undefined {
        return this.deploymentDescription
    }

    get deploymentDescription(): string | undefined {
        return this.dto.deploymentDescription
    }

    set deploymentDescription(desc: string | undefined) {
        this.dto.deploymentDescription = desc
    }

    get deploymentStatus(): DeploymentStatus {
        return this.dto.deploymentStatus
    }

    get deploymentStatusReason(): DeploymentStatusReason | undefined {
        return this.dto.deploymentStatusReason
    }

    get streamIds(): string[] {
        return this.dto.deploymentDefinition?.streamIds ?? []
    }

    set streamIds(newStreamIds: string[]) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set streamIds. deploymentDefinition is not defined')
        }

        this.dto.deploymentDefinition.streamIds = newStreamIds

        if (newStreamIds.length > 0) {
            const [streamId] = newStreamIds

            const regionAttachments: RegionAttachment[] = this.staticItems.flatMap((si) =>
                si.attributes.filter((a): a is RegionAttr => a.valueType === 'region').flatMap((a) => a.value),
            )

            regionAttachments.forEach((ra) => {
                ra.streamId = streamId
            })

            this.detectableItemConfigs.forEach((d) => {
                d.streamId = streamId
            })
        }
    }

    get launchTargets(): LaunchTarget {
        return this.dto.deploymentDefinition?.launchTargets.drt ?? { type: 'Kibsi_SAAS' }
    }

    set launchTargets(target: LaunchTarget) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set launchTargets. deploymentDefinition is not defined')
        }

        this.dto.deploymentDefinition.launchTargets = {
            art: target,
            drt: target,
            vrt: target,
        }
    }

    get staticItems(): StaticItem[] {
        return this.dto.deploymentDefinition?.staticItems ?? []
    }

    set staticItems(newStaticItems: StaticItem[]) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set staticItems. deploymentDefinition is not defined')
        }
        this.dto.deploymentDefinition.staticItems = newStaticItems
    }

    get detectableItemConfigs(): DetectableItemConfig[] {
        return this.dto.deploymentDefinition?.detectableItemConfigs ?? []
    }

    get appId(): string {
        return this.dto.appId
    }

    set appId(appId: string) {
        this.dto.appId = appId
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get versionId(): string {
        return this.dto.versionId
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get isDraft(): boolean {
        return this.dto.deploymentDraftModified
    }

    /**
     * This will return the item types defined by the deployed app version
     */
    getItemTypes(): ItemType[] {
        return this.version?.versionDefinition?.definition?.itemTypes ?? []
    }

    getItemTypeById(id: string): ItemType | undefined {
        return this.getItemTypes().find((i) => i.id === id)
    }

    getItemTypesByGenerationType(generationType: GenerationType): ItemType[] {
        return this.getItemTypes().filter((item) => item.generationType === generationType)
    }

    getStaticItemTypes(): ItemType[] {
        return this.getItemTypesByGenerationType('static')
    }

    getOrderedItemTypes(): ItemType[] {
        return [...this.getStaticItemTypes(), ...this.getDetectedItemTypes(), ...this.getEventItemTypes()]
    }

    getDetectedItemTypes(): ItemType[] {
        return this.getItemTypesByGenerationType('detected')
    }

    getEventItemTypes(): EventItemType[] {
        return this.getItemTypesByGenerationType('event') as EventItemType[]
    }

    getStaticRegions(streamId?: string): ItemPolygon[] {
        if (!streamId) {
            return []
        }

        return this.staticItems.flatMap((si) => getRegions(si, this.getItemTypeById(si.itemTypeId), streamId))
    }

    getStaticItemTypesWithCounterAttr(): ItemType[] {
        return this.getStaticItemTypes().filter((item) =>
            item.attributes?.some((attr) => attr.value.valueType === 'count'),
        )
    }

    getEventAndSourceItemType(eventTypeId: string): [ItemType | undefined, ItemType | undefined] {
        const eventItemType = this.getItemTypeById(eventTypeId)
        const eventSourceItemType = eventItemType?.event?.sourceItemId
            ? this.getItemTypeById(eventItemType.event.sourceItemId)
            : undefined
        return [eventItemType, eventSourceItemType]
    }

    getDetectableItemConfig(itemTypeId?: string): DetectableItemConfig | undefined {
        return this.detectableItemConfigs.find((detectableItem) => detectableItem.itemTypeId === itemTypeId)
    }

    updateDetectableOptions(itemTypeId: string, streamId?: string, options?: DetectableItemConfigOptions): void {
        log.info('updating', itemTypeId, streamId, options)
        if (!streamId) {
            // update all DetectableItemConfig,  get from deployment
            this.streamIds.forEach((iStreamId) => {
                this.updateDetectableOptionsWithStream(itemTypeId, iStreamId, options)
            })
        } else {
            this.updateDetectableOptionsWithStream(itemTypeId, streamId, options)
        }
    }

    private updateDetectableOptionsWithStream(
        itemTypeId: string,
        streamId: string,
        options?: DetectableItemConfigOptions,
    ) {
        const detectableItem = this.detectableItemConfigs.find(
            (item) => item.itemTypeId === itemTypeId && item.streamId === streamId,
        )
        if (!detectableItem) {
            this.detectableItemConfigs.push({
                itemTypeId,
                streamId,
                detectable: true,
                options,
            })
        } else {
            detectableItem.options = options
        }
    }

    /**
     * calculates if this deployment is a new deployment
     */
    isNewDeployment(): boolean {
        return !this.dto.deploymentStatus || this.dto.deploymentStatus === 'Created'
    }

    /**
     * determines if the deployment has stopped
     */
    isStopped(): boolean {
        return this.dto.deploymentStatus === 'Stopped' || this.dto.deploymentStatus === 'Error'
    }

    async discard(): Promise<void> {
        const dto = await this.service.discard(this.deploymentId)

        runInAction(() => {
            populate(this, dto)
        })
    }

    updateVersion(appVersion: AppVersionDto, userApproved = false): UpdateAppVersionResult {
        const orphanStaticItems = this.findOrphanStaticItems(appVersion)
        if (orphanStaticItems.length > 0 && !userApproved) {
            return {
                status: 'approvalNeeded',
                appVersion,
                staticItemsToDelete: orphanStaticItems,
            }
        }

        // actual update App version
        this.staticItems = this.staticItems.filter((item) => !orphanStaticItems.includes(item.id))
        this.dto.versionId = appVersion.versionId
        this.version = appVersion

        return {
            status: 'success',
            appVersion,
        }
    }

    saveStaticItem(staticItem: StaticItem): void {
        // remove attributes that do not have any values
        const sanitizedStaticItem = {
            ...staticItem,
            attributes: staticItem.attributes.filter(
                (attr) => attr.value !== undefined && attr.value !== null && attr.value !== '',
            ),
        }
        // find the static item that has changed
        const existingItem = this.staticItems.find((item) => item.id === staticItem.id)
        if (!existingItem) {
            // push the new item on the static items array
            this.staticItems.push(sanitizedStaticItem)
        } else {
            // updates the existing item
            Object.assign(existingItem, sanitizedStaticItem)
        }
    }

    deleteStaticItem(staticItemToDelete: StaticItem): void {
        this.staticItems = this.staticItems.filter((item) => item.id !== staticItemToDelete.id)
    }

    bulkAddStaticItems(bulkAddRequest: BulkAddStaticItemsType): void {
        const staticItemTypes = this.getStaticItemTypes()
        staticItemTypes.forEach((iType) => {
            const val = bulkAddRequest[iType.id] || 0
            if (val > 0) {
                for (let i = 0; i < val; i++) {
                    const newStaticItem = {
                        id: nanoid(),
                        itemTypeId: iType.id,
                        attributes: [],
                    }

                    this.staticItems.push(newStaticItem)
                }
            }
        })
    }

    findOrphanStaticItems(newAppVersion: AppVersionDto): string[] {
        const itemTypes = newAppVersion.versionDefinition?.definition?.itemTypes ?? []
        return this.staticItems
            .filter((si) => itemTypes.findIndex((it) => it.id === si.itemTypeId) === -1)
            .map((si) => si.id)
    }

    async loadVdbEndpoint(): Promise<VdbEndpoint> {
        const newVdbEndpoint = await this.service.getVdbEndpoint(this.deploymentId)
        return runInAction(() => {
            this.vdbEndpoint = newVdbEndpoint
            return newVdbEndpoint
        })
    }

    async refreshStatus(): Promise<DeploymentStatus> {
        const newDto = await this.service.getDto(this.deploymentId)
        return runInAction(() => {
            this.dto.deploymentStatus = newDto.deploymentStatus
            this.dto.currentLaunchConfigId = newDto.currentLaunchConfigId
            return newDto.deploymentStatus
        })
    }

    async restart(): Promise<void> {
        try {
            await this.service.restart(this.deploymentId)
        } catch (err) {
            throw new Error(parseErrorMessage(err))
        }
        await this.refreshStatus()
    }

    toDto(): Dto {
        return toJS(this.dto)
    }

    fromDto(dto: Dto): void {
        this.dto = { ...dto, deploymentId: this.deploymentId }
        this.skipUpdate = true
    }

    /**
     * these are fields that are editable by the ui
     */
    get editable(): DeploymentEditable {
        if (!this.dto.deploymentDefinition) {
            throw new Error('trying to update deployment, but no deploymentDefinition is defined')
        }

        return {
            deploymentName: this.deploymentName,
            deploymentDescription: this.deploymentDescription,
            versionId: this.dto.versionId,
            deploymentDefinition: this.dto.deploymentDefinition,
        }
    }

    /**
     * this is the data to check for an update reaction
     * reactions are determined when a property is accessed. So to test whether a reaction has happened, we need to observer the properties that have been accessed
     *
     * @returns
     */
    private reactionDataUpdate() {
        return {
            deploymentName: this.deploymentName,
            deploymentDescription: this.deploymentDescription,
            versionId: this.versionId,
            streamIds: this.streamIds.map((s) => s),
            staticItems: this.staticItems.map((si) => si.id),
            staticItemAttributes: this.staticItems.map((sItem) => sItem.attributes),
            detectableItemConfigs: JSON.stringify(this.detectableItemConfigs),
            launchTargets: this.launchTargets,
            regions: this.staticItems.map((sItem) =>
                sItem.attributes.map((attr) => {
                    if (isRegionAttr(attr)) {
                        return attr.value.map((r) => r)
                    }

                    if (isEnhancerAttr(attr)) {
                        return attr.value.regionId
                    }

                    return attr.value
                }),
            ),
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            // to check if the deployment needs to update via reactions, we have to access all the properties that are updatable
            reaction(
                () => this.reactionDataUpdate(),
                () => {
                    if (!this.skipUpdate) {
                        this.update(this.editable).catch((e) => {
                            log.error('auto deployment update failure:', e)
                        })
                    }
                },
                {
                    // comparer to do a deep equal comparison whether to fire a reaction
                    equals: comparer.structural,
                    // delay before we save
                    delay: this.updateDelay,
                },
            ),
            reaction(
                () => this.skipUpdate,
                (skip) => {
                    if (skip) {
                        this.resetSkip()
                    }
                },
            ),
        )
    }

    private async update(editable: DeploymentEditable): Promise<void> {
        const action = this.service.update({ ...editable, deploymentId: this.deploymentId })

        this.status = fromPromise(action, this.status)

        await action
    }

    private resetSkip() {
        this.skipUpdate = false
    }
}

function isApp(app: AppDto | AppRef | null): app is AppDto {
    return app != null && 'name' in app
}

function isVersion(version: AppVersionDto | VersionRef | null): version is AppVersionDto {
    return version != null && 'versionId' in version
}

function isSite(site: SiteDto | SiteRef | null): site is SiteDto {
    return site != null && 'siteName' in site
}

export function isRegionAttr(attr: AttrValue): attr is RegionAttr {
    const regionAttr = attr as RegionAttr
    return (
        Array.isArray(regionAttr.value) &&
        Array.isArray(regionAttr.value.map((r) => r)) &&
        Array.isArray(regionAttr.value.map((r) => r.region.path))
    )
}

export function isEnhancerAttr(attr: AttrValue): attr is EnhancerAttr {
    const enhancerAttr = attr as EnhancerAttr
    return enhancerAttr.value !== undefined && enhancerAttr.value.enhancerId !== undefined
}

export function destructure(
    dto: AnyDto,
): [Dto, AppDto | AppRef | null, AppVersionDto | VersionRef | null, SiteDto | SiteRef | null] {
    const { app, version, site, ...rest } = dto

    const data: Dto = {
        ...rest,
        appId: app?.id ?? '',
        versionId: version?.versionId ?? '',
        siteId: site?.siteId ?? '',
    }

    return [data, app, version, site]
}

export function setRefs(
    deployment: Deployment,
    app: AppDto | AppRef | null,
    version: AppVersionDto | VersionRef | null,
    site: SiteDto | SiteRef | null,
): void {
    if (isApp(app)) {
        deployment.app = app
    }

    if (isVersion(version)) {
        deployment.version = version
    }

    if (isSite(site)) {
        deployment.site = site
    }
}

export function populate(deployment: Deployment, data: AnyDto): void {
    const [dto, app, version, site] = destructure(data)

    deployment.fromDto(dto)

    setRefs(deployment, app, version, site)
}

// this is the sort order of the deployment status
const DEPLOYMENT_STATUS_SORT_ORDER = ['Created', 'Error', 'Stopped']

const getDeploymentSortIndex = (status: DeploymentStatus): number => {
    switch (status) {
        case 'Attempt Retry':
        case 'In Progress':
        case 'Pending':
        case 'Running':
            return 0
        default:
            return DEPLOYMENT_STATUS_SORT_ORDER.indexOf(status) + 1
    }
}

export const deploymentListSorter = (a: Deployment, b: Deployment): number =>
    getDeploymentSortIndex(a.deploymentStatus) - getDeploymentSortIndex(b.deploymentStatus) ||
    b.lastUpdated.timestamp.localeCompare(a.lastUpdated.timestamp)
