import { AttributeTypeDef, ItemType } from '@kibsi/ks-application-types'
import {
    Data,
    EnhancerAttr,
    RegionAttachment,
    RegionAttr,
    RegionType,
    SimpleAttr,
    StaticItem,
} from '@kibsi/ks-deployment-types'
import { ItemPolygon, NamedPolygon } from 'components/draw'
import logger from 'logging/logger'
import { nanoid } from 'nanoid'
import { Deployment } from 'store/deployment'
import { Point } from 'model/draw'
import { AttrValue, DrawRegionRequest, SimpleValue, SimpleValuesFormData, StaticItemEditFormType } from './types'

export function filterStaticItemTypes(deployment: Deployment): ItemType[] {
    const itemTypes = deployment?.version?.versionDefinition?.definition?.itemTypes ?? []
    return itemTypes.filter((item) => item.generationType === 'static')
}

export function dataToSimpleValue(data: Data | undefined): SimpleValue {
    if (!data) {
        return ''
    }

    if (Array.isArray(data)) {
        // we will only accept primitive types
        return ''
    }
    return data
}

export function defaultAttributeValueToSimpleValue(def: AttributeTypeDef): SimpleValue {
    if (def.value.valueType !== 'value') {
        return `unknown ${def.value.valueType}`
    }

    return dataToSimpleValue(def.value.defaultValue)
}

export function attributeValueToSimpleValue(savableAttribute: AttrValue): SimpleValue {
    if (!savableAttribute) {
        return ''
    }

    if (savableAttribute.valueType !== 'value') {
        logger.warn(`unknown ${savableAttribute.valueType}`)
        return `unknown ${savableAttribute.valueType}`
    }

    return dataToSimpleValue(savableAttribute.value)
}

export function buildFormData(
    staticItem: StaticItem,
    attributeDefinitions: AttributeTypeDef[],
): StaticItemEditFormType {
    return attributeDefinitions.reduce<StaticItemEditFormType>((map, def) => {
        const attributeDefId = def.id

        const attrVal = staticItem.attributes?.find((sItem) => sItem.id === attributeDefId)

        if (attrVal) {
            map[attributeDefId] = attributeValueToSimpleValue(attrVal)
        } else {
            map[attributeDefId] = defaultAttributeValueToSimpleValue(def)
        }
        return map
    }, {})
}

export function toAttrValue(
    aDef: AttributeTypeDef,
    val: string | number | boolean | Array<string | number | boolean> | undefined,
): AttrValue {
    if (aDef.value.valueType === 'region') {
        return {
            id: aDef.id,
            valueType: 'region',
            value: [],
        }
    }

    // by default everything will return value
    return {
        id: aDef.id,
        valueType: 'value',
        value: val ?? '',
    }
}

export function mergeSimpleValuesAttributes(
    existingAttributes: Array<SimpleAttr | RegionAttr | EnhancerAttr>,
    attributeDefinitions: AttributeTypeDef[],
    formData: SimpleValuesFormData,
): Array<SimpleAttr | RegionAttr | EnhancerAttr> {
    const simpleAttrDefs = attributeDefinitions?.filter((attr) => attr.value.valueType === 'value') ?? []

    const oldAttributes = [...existingAttributes]

    const newAttributes: Array<AttrValue> = simpleAttrDefs
        // filter out attribute that do not have any value
        .filter((aDef) => formData[aDef.id] !== undefined && formData[aDef.id] !== '')
        .map((aDef) => toAttrValue(aDef, formData[aDef.id]))

    const mergedAttributes = newAttributes.reduce((mergedArray, newVal) => {
        const index = mergedArray.findIndex((oldVal) => oldVal.id === newVal.id)
        if (index > -1) {
            // override the old id with the new id
            mergedArray[index] = newVal
        } else {
            mergedArray.push(newVal)
        }
        return mergedArray
    }, oldAttributes)

    return mergedAttributes
}

function mergeStaticRegionAttributes(
    existingAttributes: Array<SimpleAttr | RegionAttr | EnhancerAttr>,
    drawRequest: Readonly<DrawRegionRequest>,
    path: number[][],
    regionType: RegionType = 'PolygonRegion',
): Array<SimpleAttr | RegionAttr | EnhancerAttr> {
    const { attr: attrDef, regionAttachment: existingRegion, streamId } = drawRequest

    const newRegion: RegionAttachment = {
        streamId: existingRegion?.streamId ?? streamId,
        region: {
            id: existingRegion?.region.id ?? nanoid(),
            type: regionType,
            path,
        },
    }

    const existingAttr = existingAttributes.find((a) => a.id === attrDef.id) as RegionAttr

    if (!existingAttr) {
        const newRegionAttr: RegionAttr = {
            id: attrDef.id,
            valueType: 'region',
            value: [newRegion],
        }
        existingAttributes.push(newRegionAttr)
    } else {
        const regions = existingAttr.value ?? []
        const index = regions.findIndex((oldRegion) => oldRegion.streamId === streamId)
        if (index > -1) {
            regions[index] = newRegion
        } else {
            regions.push(newRegion)
        }
    }

    return existingAttributes
}

function mergeEnhancerRegionAttributes(
    existingAttributes: Array<SimpleAttr | RegionAttr | EnhancerAttr>,
    drawRequest: Readonly<DrawRegionRequest>,
    path: number[][],
    regionType: RegionType = 'PolygonRegion',
): Array<SimpleAttr | RegionAttr | EnhancerAttr> {
    const { attr: attrDef, regionAttachment: existingRegion, streamId } = drawRequest
    const { id: enhancerAttrId } = attrDef
    const enhancerRegionId = getEnhancerRegionId(attrDef)
    const newRegion: RegionAttachment = {
        streamId: existingRegion?.streamId ?? streamId,
        region: {
            id: existingRegion?.region.id ?? nanoid(),
            type: regionType,
            path,
        },
    }

    const existingEnhancerAttr = existingAttributes.find((a) => a.id === enhancerAttrId) as EnhancerAttr
    const existingRegionAttr = existingAttributes.find((a) => a.id === enhancerRegionId) as RegionAttr

    if (!existingEnhancerAttr) {
        let enhancerId = ''

        if (attrDef.value.valueType === 'enhancer') {
            enhancerId = attrDef.value.enhancer.enhancerId
        }

        const newEnhancerAttr: EnhancerAttr = {
            id: enhancerAttrId,
            valueType: 'enhancer',
            value: {
                regionId: enhancerRegionId,
                enhancerId,
            },
        }

        existingAttributes.push(newEnhancerAttr)
    } else {
        existingEnhancerAttr.value.regionId = enhancerRegionId
    }

    if (!existingRegionAttr) {
        const newRegionAttr: RegionAttr = {
            id: enhancerRegionId,
            valueType: 'region',
            value: [newRegion],
        }
        existingAttributes.push(newRegionAttr)
    } else {
        const regions = existingRegionAttr.value ?? []
        const index = regions.findIndex((oldRegion) => oldRegion.streamId === streamId)
        if (index > -1) {
            regions[index] = newRegion
        } else {
            regions.push(newRegion)
        }
    }

    return existingAttributes
}

export function mergeRegionAttributes(
    existingAttributes: Array<SimpleAttr | RegionAttr | EnhancerAttr>,
    drawRequest: Readonly<DrawRegionRequest>,
    path: number[][],
    regionType: RegionType = 'PolygonRegion',
): Array<SimpleAttr | RegionAttr | EnhancerAttr> {
    const { attr } = drawRequest
    if (attr.value.valueType === 'enhancer') {
        return mergeEnhancerRegionAttributes(existingAttributes, drawRequest, path, regionType)
    }

    return mergeStaticRegionAttributes(existingAttributes, drawRequest, path, regionType)
}

export function mergeEnhancerRegionSelectAttributes(
    existingAttributes: Array<SimpleAttr | RegionAttr | EnhancerAttr>,
    attribute: AttributeTypeDef,
    regionReference: string | undefined,
): Array<SimpleAttr | RegionAttr | EnhancerAttr> {
    const attributes = [...existingAttributes]
    const { id: enhancerAttrId } = attribute

    // update EnhancerAttr region ref
    const existingEnhAttr = attributes.find((eAttr) => eAttr.id === enhancerAttrId) as EnhancerAttr
    if (!existingEnhAttr) {
        let enhancerId = ''

        if (attribute.value.valueType === 'enhancer') {
            enhancerId = attribute.value.enhancer.enhancerId
        }

        const newEnhancerAttr: EnhancerAttr = {
            id: enhancerAttrId,
            valueType: 'enhancer',
            value: {
                regionId: regionReference,
                enhancerId,
            },
        }

        attributes.push(newEnhancerAttr)
    } else {
        existingEnhAttr.value.regionId = regionReference
    }

    // remove any custom region for enhancer
    const regionIndex = attributes.findIndex((a) => a.id === getEnhancerRegionId(attribute))
    if (regionIndex > -1) {
        attributes.splice(regionIndex, 1)
    }

    return attributes
}

export const getDisplayAttributeLabel = (
    staticItem: StaticItem,
    displayAttributeId: string | undefined,
    defaultLabel: Data,
): Data => {
    const displayAttr = staticItem.attributes.find((attr) => attr.id === displayAttributeId)

    if (displayAttr) {
        if (displayAttr.valueType === 'value') {
            return displayAttr.value
        }
    }

    return defaultLabel
}

export const getRegions = (staticItem: StaticItem, itemType?: ItemType, streamId?: string): ItemPolygon[] => {
    const regionAttrs = staticItem.attributes.filter((attr) => attr.valueType === 'region') as RegionAttr[]

    return regionAttrs
        .flatMap((attr) => attr.value)
        .filter((ra) => ra.streamId === streamId)
        .map((ra) => ({
            id: ra.region.id,
            name: String(getDisplayAttributeLabel(staticItem, itemType?.displayAttributeId, '')) || '',
            polygon: ra.region.path as Point[],
            itemType: itemType?.name || '',
        }))
}

export const getOtherRegions = (
    staticItems: StaticItem[],
    currentStaticItem: StaticItem,
    currentItemType: ItemType,
    attrId: string,
    streamId: string,
): NamedPolygon[] =>
    staticItems
        .filter((si) => si.itemTypeId === currentItemType.id) // exclude the current static item and static item type with current item types
        .flatMap((si) =>
            si.attributes
                .filter((attr): attr is RegionAttr => attr.valueType === 'region') // filter Region Attr
                .filter((attr) => si.id !== currentStaticItem.id || attr.id !== attrId)
                .flatMap((attr) => attr.value) // flat map to list of RegionAttachments
                .filter((ra) => ra.streamId === streamId) // filter by current streamId
                .map((ra) => ({
                    id: ra.region.id,
                    name: String(
                        getDisplayAttributeLabel(si, currentItemType.displayAttributeId, currentItemType.name),
                    ),
                    polygon: ra.region.path as Point[],
                })),
        ) // flat map to list of attributes

export const getEnhancerRegionId = (attr: AttributeTypeDef): string => `${attr.id}.region`
