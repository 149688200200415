import { UserKibsiClient } from '@kibsi/ks-client-sdk'
import { SelfPasswordUpdate, SelfUserUpdate, User, UserCreate, UserUpdate } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { UserService } from './user.service'

export type UserApi = Pick<
    InstanceType<typeof UserKibsiClient>,
    | 'list'
    | 'read'
    | 'getSelf'
    | 'updateSelf'
    | 'setSelfPassword'
    | 'create'
    | 'update'
    | 'delete'
    | 'getProfilePictureUrl'
>

@injectable()
export class UserServiceImpl implements UserService {
    constructor(@inject(TYPES.RequestStore) private req: RequestStore, @inject(TYPES.UserApi) private api: UserApi) {}

    async list(): Promise<User[]> {
        return this.req.once('user.list', async () => {
            const { results } = await this.api.list()

            return results
        })
    }

    get(id: string): Promise<User> {
        return this.req.once(`site.get.${id}`, () => this.api.read(id))
    }

    getSelf(): Promise<User> {
        return this.req.once(`site.get.self`, () => this.api.getSelf())
    }

    getProfilePictureUrl(userId: string): Promise<string> {
        return this.req.once(`user.${userId}.picture`, () => this.api.getProfilePictureUrl(userId))
    }

    updateSelf(user: SelfUserUpdate): Promise<void> {
        return this.api.updateSelf(user)
    }

    updateSelfPassword(user: SelfPasswordUpdate): Promise<void> {
        return this.api.setSelfPassword(user)
    }

    create(user: UserCreate): Promise<User> {
        return this.api.create(user)
    }

    update(user: UserUpdate): Promise<void> {
        return this.api.update(user)
    }

    delete(userId: string): Promise<void> {
        return this.api.delete({ userId } as User)
    }
}
