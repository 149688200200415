import { SelfPasswordUpdate, SelfUserUpdate, User, UserCreate, UserCriteria, UserUpdate } from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { PaginationRequest, PaginationResponse } from 'types'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class UserKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<UserCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<User>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { authPrincipal } = criteria ?? {}
        return this.get(`/users`, {
            ...requestConfig,
            ...{ params: { continuationToken, pageSize, sort, authPrincipal } },
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<User> {
        return this.get(`/users/${entityId}`, requestConfig)
    }

    getSelf(requestConfig?: AxiosRequestConfig): Promise<User> {
        return this.get(`/users/self`, requestConfig)
    }

    updateSelf(entity: SelfUserUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/users/self`, entity, requestConfig)
    }

    setSelfPassword(entity: SelfPasswordUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/users/self/password`, entity, requestConfig)
    }

    create(entity: UserCreate, requestConfig?: AxiosRequestConfig): Promise<User> {
        return this.post(`/users`, entity, requestConfig)
    }

    update(entity: UserUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/users/${entity.userId}`, entity, requestConfig)
    }

    delete(entity: User, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/users/${entity.userId}`, requestConfig)
    }

    getProfilePictureUrl(userId: string, requestConfig?: AxiosRequestConfig): Promise<string> {
        return this.get(`/users/${userId}/picture`, requestConfig)
    }
}
