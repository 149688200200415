import { useCallback, useEffect, useState } from 'react'
import { IPromiseBasedObservable } from 'mobx-utils'

import { fromPromise } from 'util/mobx'
import { useErrorStore } from './error/useErrorStore'
import { KibsiError } from '../store/error/error'

export type Status<T = unknown> = IPromiseBasedObservable<T>

export interface Updater<T> {
    (value: Promise<T>): void
}

type ErrorHandler = (err: Error) => void | never

function useManageError<T>(status?: Status<T>, errorHandler?: ErrorHandler) {
    const errorStore = useErrorStore()
    const error = usePromiseError(status)

    useEffect(() => {
        if (!error) {
            return
        }

        const uncaughtHandler = (e: unknown) => {
            if (e instanceof Error) {
                errorStore.addError(KibsiError.fromError(e))
            }
        }

        if (errorHandler) {
            try {
                errorHandler(error)
            } catch (e) {
                uncaughtHandler(e)
            }
        } else {
            uncaughtHandler(error)
        }
    }, [error, errorHandler, errorStore])
}

// I'm sure there is a way to define the types in both of the following methods in a way to combine into one. The
// complexity and effort aren't worth it now.

export function usePromiseState<T>(initial: () => Promise<T>, errorHandler?: ErrorHandler): [Status<T>, Updater<T>] {
    const [status, setStatus] = useState(() => fromPromise(initial()))

    const update: Updater<T> = useCallback((value) => {
        setStatus(fromPromise(value))
    }, [])

    useManageError(status, errorHandler)

    return [status, update]
}

export interface LaterUpdater<T> {
    (value: Promise<T> | undefined): void
}

export function usePromiseStateLater<T>(errorHandler?: ErrorHandler): [Status<T> | undefined, LaterUpdater<T>] {
    const [status, setStatus] = useState<IPromiseBasedObservable<T>>()

    const update: LaterUpdater<T> = useCallback((value) => {
        if (value !== undefined) {
            setStatus(fromPromise(value))
        } else {
            setStatus(undefined)
        }
    }, [])

    useManageError(status, errorHandler)

    return [status, update]
}

export function usePromiseValue<T>(promise: IPromiseBasedObservable<T>, empty: T): T {
    return promise.state === 'fulfilled' ? promise.value : empty
}

export function usePromiseError<T>(promise?: IPromiseBasedObservable<T>): Error | undefined {
    if (promise) {
        return promise.state === 'rejected' ? (promise.value as Error) : undefined
    }
    return undefined
}
