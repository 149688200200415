import { ReactElement } from 'react'
import { alpha, darken, Button, ButtonProps } from '@mui/material'
import { asSxArray } from '../../../utils/mui'

export interface KBButtonOptionProps<T = unknown> extends Omit<ButtonProps, 'value'> {
    value?: T
    selected?: boolean
}

export function KBButtonOption<T>({ value, selected, children, sx, ...rest }: KBButtonOptionProps<T>): ReactElement {
    const className = selected ? 'selected' : undefined

    return (
        <Button
            {...rest}
            data-value={value}
            className={className}
            sx={[
                (theme) => ({
                    textTransform: 'unset',
                    '&.MuiButton-contained': {
                        backgroundColor: theme.palette.primary.contrastText,
                        boxShadow: 1,
                        color: 'text.primary',
                        fontWeight: theme.typography.fontWeightRegular,
                        '&.selected': {
                            boxShadow: 0,
                        },
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            boxShadow: 1,
                            color: 'primary.main',
                        },
                    },
                    '&.MuiButton-outlined': {
                        borderColor: 'primary.outlinedRestingBackground',
                        '&.selected': {
                            backgroundColor: 'primary.main',
                            borderColor: 'transparent',
                            color: 'primary.contrastText',
                            ':hover': {
                                backgroundColor: darken('#7C44FC', 0.3),
                            },
                        },
                        '&:hover': {
                            backgroundColor: 'primary.outlinedHoverBackground',
                        },
                    },
                    '&.selected': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        boxShadow: 1,
                        color: 'primary.main',
                        fontWeight: theme.typography.fontWeightMedium,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'primary.contrastText',
                        borderColor: 'action.disabled',
                        color: 'text.disabled',
                    },
                }),
                ...asSxArray(sx),
            ]}
        >
            {children}
        </Button>
    )
}
