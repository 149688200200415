import { AxiosRequestConfig } from 'axios'
import {
    Application,
    ApplicationCreate,
    ApplicationCriteria,
    ApplicationUpdate,
    ApplicationVersion,
    ApplicationVersionCreate,
    ApplicationVersionDetails,
    ApplicationVersionCriteria,
    AppModelDefinition,
    ApplicationVersionUpdate,
} from '@kibsi/ks-application-types'
import { PaginationRequest, PaginationResponse } from 'types/pagination'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'application'

export class ApplicationKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(
        paginationRequest?: PaginationRequest<ApplicationCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<Application>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { ids, published } = criteria ?? {}
        return this.get('/', {
            ...(paginationRequest && { params: { continuationToken, pageSize, sort, ids: ids?.join(','), published } }),
            ...requestConfig,
        })
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Application> {
        return this.get(`/${entityId}`, requestConfig)
    }

    create(entity: ApplicationCreate, requestConfig?: AxiosRequestConfig): Promise<Application> {
        return this.post(`/`, entity, requestConfig)
    }

    update(entity: ApplicationUpdate, requestConfig?: AxiosRequestConfig): Promise<Application> {
        return this.put(`/${entity.id}`, entity, requestConfig)
    }

    delete(entity: Application, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/${entity.id}`, requestConfig)
    }

    readModelDefinition(entityId: string, requestConfig?: AxiosRequestConfig): Promise<AppModelDefinition> {
        return this.get(`/${entityId}/modeldefinition`, requestConfig)
    }

    updateModelDefinition(
        entityId: string,
        definition: AppModelDefinition,
        requestConfig?: AxiosRequestConfig,
    ): Promise<void> {
        return this.put(`/${entityId}/modeldefinition`, definition, requestConfig)
    }

    listVersions(
        paginationRequest?: PaginationRequest<Pick<ApplicationVersionCriteria, 'versionIds'>>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<ApplicationVersionDetails>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { versionIds } = criteria ?? {}

        return this.get(`/versions`, {
            ...requestConfig,
            params: { continuationToken, pageSize, sort, versionIds: versionIds?.join(',') },
        })
    }

    listApplicationVersions(
        applicationId: string,
        paginationRequest?: PaginationRequest<ApplicationVersionCriteria>,
        requestConfig?: AxiosRequestConfig,
    ): Promise<PaginationResponse<ApplicationVersionDetails>> {
        const { continuationToken, pageSize, sort, criteria } = paginationRequest || {}
        const { versionIds } = criteria ?? {}

        return this.get(`/${applicationId}/versions`, {
            ...requestConfig,
            params: { continuationToken, pageSize, sort, versionIds: versionIds?.join(',') },
        })
    }

    createVersion(
        applicationId: string,
        entity: ApplicationVersionCreate,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ApplicationVersion> {
        return this.post(`/${applicationId}/versions`, entity, requestConfig)
    }

    readVersion(versionId: string, requestConfig?: AxiosRequestConfig): Promise<ApplicationVersion> {
        return this.get(`/versions/${versionId}`, requestConfig)
    }

    updateVersion(
        versionId: string,
        entity: ApplicationVersionUpdate,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ApplicationVersion> {
        return this.put(`/versions/${versionId}`, entity, requestConfig)
    }
}
