import { Audit, FloorPlan as FloorPlanDto, FloorPlanUpdate } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, reaction, toJS } from 'mobx'
import { FloorPlanService } from 'service/floor-plan'
import { IDisposer } from 'mobx-utils'
import { JpegDataBuffer } from 'store/data-buffer'
import { FromDto, ToDto } from '../interfaces'
import { FloorPlanStream } from './floor-plan.stream'
import { Dimension } from '../../util/dimension'

export type FloorPlanDataUpdate = Omit<FloorPlanUpdate, 'floorPlanId'>

const POINTS_REQUIRED_FOR_FLOOR_PLAN_SETUP = 4

export class FloorPlan implements ToDto<FloorPlanDto>, FromDto<FloorPlanDto> {
    private reactions: IDisposer[] = []
    private streamsList: FloorPlanStream[] = []
    readonly id: string

    image?: JpegDataBuffer

    constructor(private dto: FloorPlanDto, private service: FloorPlanService) {
        this.id = dto.floorPlanId
        this.streamsList = this.dto.streams.map((s) => new FloorPlanStream(s))

        makeAutoObservable<FloorPlan, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    get name(): string {
        return this.dto.floorPlanName
    }

    set name(value: string) {
        this.dto.floorPlanName = value
    }

    get description(): string | undefined {
        return this.dto.floorPlanDescription
    }

    set description(value: string | undefined) {
        this.dto.floorPlanDescription = value
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get dimensions(): Dimension | undefined {
        if (this.dto.floorPlanHeight === undefined || this.dto.floorPlanWidth === undefined) {
            return undefined
        }

        return {
            width: this.dto.floorPlanWidth,
            height: this.dto.floorPlanHeight,
        }
    }

    get created(): Audit {
        return this.dto.created
    }

    get createdDate(): Date {
        return new Date(this.dto.created.timestamp)
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get lastUpdatedDate(): Date {
        return new Date(this.dto.lastUpdated.timestamp)
    }

    get streams(): FloorPlanStream[] {
        return this.streamsList
    }

    set streams(streams: FloorPlanStream[]) {
        this.streamsList = streams
    }

    async update(update: FloorPlanUpdate): Promise<void> {
        const dto = await this.service.update(update)

        this.fromDto(dto)
    }

    get isSetupComplete(): boolean {
        const completed = this.streams.filter(
            (s) => this.getMappingCountsForStream(s.streamId) >= POINTS_REQUIRED_FOR_FLOOR_PLAN_SETUP,
        )

        return completed.length === this.streams.length && this.streams.length !== 0
    }

    getFloorPlanStream(streamId: string): FloorPlanStream | undefined {
        return this.streams.find((s) => s.streamId === streamId)
    }

    addStreams(streamIds: string[]): void {
        const addedStreams: FloorPlanStream[] = streamIds.map(
            (streamId) =>
                new FloorPlanStream({
                    streamId,
                    mappings: [],
                }),
        )

        this.streams = [...this.streams, ...addedStreams]
    }

    deleteStream(streamId: string): void {
        this.streams = this.streams.filter((s) => s.streamId !== streamId)
    }

    getMappingCountsForStream(streamId: string): number {
        return this.getFloorPlanStream(streamId)?.mappings?.length ?? 0
    }

    async getImage(): Promise<JpegDataBuffer | undefined> {
        if (!this.image) {
            const buffer = await this.service.getImage(this.id)
            this.setImageData(buffer)
        }
        return this.image
    }

    async setImage(data: ArrayBuffer): Promise<void> {
        await this.service.saveImage(this.id, data, 'image/jpeg')

        this.setImageData(data)
    }

    toDto(): FloorPlanDto {
        return {
            ...toJS(this.dto),
            streams: this.streams.map((s) => s.toDto()),
        }
    }

    fromDto(dto: FloorPlanDto): void {
        this.dto = { ...dto, floorPlanId: this.id }
    }

    private reactionDataUpdate(): FloorPlanDataUpdate {
        return {
            floorPlanName: this.name,
            floorPlanDescription: this.description,
            streams: this.streams.map((s) => s.toDto()),
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.reactionDataUpdate(),
                (update) => {
                    this.service
                        .update({
                            floorPlanId: this.id,
                            ...update,
                        })
                        .catch(() => {})
                },
            ),
        )
    }

    private setImageData(data: ArrayBuffer) {
        if (this.image) {
            this.image.buffer = data
        } else {
            this.image = new JpegDataBuffer(data)
        }
    }
}
