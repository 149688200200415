import { inject, injectable } from 'inversify'
import {
    ARTItemState,
    CounterAggregateResult,
    DeploymentARTSnapshot,
    FloorPlanHeatmapResult,
    HeatmapMetric,
    HistorySnapshotInterval,
    ItemAggregateResult,
    ItemCountResult,
    QueryFilter,
} from '@kibsi/ks-history-types'
import T from '../../config/inversify.types'
import type { HistoryService } from '../../service/history'
import type { HistoryInterval } from '../../service/history/history.service'
import logger from '../../logging/logger'
import { addIntervalToDate } from '../../util/date-time'
import { GridSize } from '../dashboard/heatmap.options'

@injectable()
export class HistoryStore {
    constructor(@inject(T.HistoryService) private service: HistoryService) {}

    itemAggregates(
        deploymentId: string,
        startTime: number,
        endTime: number,
        interval: HistoryInterval,
    ): Promise<ItemAggregateResult> {
        logger.info('item aggregates', startTime, endTime, interval)
        return this.service.itemAggregates(
            deploymentId,
            new Date(startTime).toISOString(),
            new Date(endTime).toISOString(),
            interval,
        )
    }

    distinctItems(
        deploymentId: string,
        startTime: number,
        endTime: number,
        interval: HistoryInterval,
        filters: QueryFilter[] = [],
    ): Promise<ItemCountResult> {
        logger.info('distinct items', startTime, endTime, interval, filters)
        return this.service.distinctItems(
            deploymentId,
            new Date(startTime).toISOString(),
            new Date(endTime).toISOString(),
            interval,
            { filters },
        )
    }

    counterAggregates(
        deploymentId: string,
        itemType: string,
        name: string,
        counter: string,
        startTime: number,
        endTime: number,
        interval: HistoryInterval,
    ): Promise<CounterAggregateResult> {
        logger.info('count aggregates', itemType, name, counter, startTime, endTime, interval)
        return this.service.counterAggregates(
            deploymentId,
            itemType,
            name,
            counter,
            new Date(startTime).toISOString(),
            new Date(endTime).toISOString(),
            interval,
        )
    }

    snapshot(deploymentId: string, time: number): Promise<DeploymentARTSnapshot> {
        return this.service.snapshot(deploymentId, new Date(time).toISOString())
    }

    snapshotInterval(
        deploymentId: string,
        startTime: string,
        interval: HistoryInterval,
    ): Promise<HistorySnapshotInterval> {
        const endTime = addIntervalToDate(new Date(startTime), interval)
        return this.service.snapshotInterval(deploymentId, startTime, endTime.toISOString())
    }

    itemDetails(
        deploymentId: string,
        id: string,
        startTime: string,
        interval: HistoryInterval,
    ): Promise<ARTItemState[]> {
        const endTime = addIntervalToDate(new Date(startTime), interval)
        return this.service.itemDetails(deploymentId, startTime, endTime.toISOString(), id)
    }

    floorPlanHeatmap(
        floorPlanId: string,
        startTime: number,
        endTime: number,
        metric: HeatmapMetric,
        deploymentIds: string[] = [],
        itemTypes: string[] = [],
        grid: GridSize = 'small',
    ): Promise<FloorPlanHeatmapResult> {
        return this.service.floorPlanHeatmap(
            floorPlanId,
            new Date(startTime).toISOString(),
            new Date(endTime).toISOString(),
            { deploymentIds, itemTypes, grid, metric },
        )
    }
}
