import { Box, Drawer, DrawerProps, Toolbar } from '@mui/material'
import { PropsWithChildren, ReactElement } from 'react'

export declare type KBDrawerVariant = 'permanent' | 'persistent' | 'temporary'

export type KBDrawerProps = {
    open?: boolean
    anchor?: 'left' | 'top' | 'right' | 'bottom'
    minWidth?: number | string
    onClose?: () => void
    onDrawerClosed?: () => void
    onBackdropClick?: () => void
    variant?: 'permanent' | 'persistent' | 'temporary'
    hideBackdrop?: boolean
}

export function KBDrawer({
    anchor = 'right',
    open = false,
    minWidth = 50,
    variant = 'temporary',
    onClose,
    onDrawerClosed,
    onBackdropClick,
    hideBackdrop,
    children,
}: PropsWithChildren<KBDrawerProps>): ReactElement {
    let drawerProps: DrawerProps = {
        open,
        anchor,
        onClose,
        variant: variant as KBDrawerVariant,
        hideBackdrop,
        SlideProps: {
            onTransitionEnd: () => {
                if (!open) {
                    onDrawerClosed?.()
                }
            },
        },
    }

    // @mui will throw an error if we try to provide an onBackdropClick handler if it's not of 'temporary' variant type
    if (variant === 'temporary') {
        drawerProps = {
            ...drawerProps,
            onBackdropClick,
        }
    }

    return (
        <Drawer
            data-testid="kb-drawer"
            {...drawerProps}
            sx={{
                minWidth,
                [`& .MuiDrawer-paper`]: {
                    minWidth,
                    overflow: 'hidden',
                },
            }}
        >
            <Toolbar variant="dense" />
            <Box display="flex" flexGrow={1} sx={{ overflowY: 'auto' }}>
                {children}
            </Box>
        </Drawer>
    )
}
