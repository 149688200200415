import type { Audit, SelfUserUpdate, User as UserDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, reaction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { UserService } from 'service/user'
import { FromDto, ToDto } from 'store/interfaces'
import logger from 'logging/logger'

export class User implements ToDto<UserDto>, FromDto<UserDto>, UserDto {
    private reactions: IDisposer[] = []

    readonly userId: string

    constructor(private dto: UserDto, private service: UserService) {
        this.userId = dto.userId
        makeAutoObservable<User, 'service' | 'reactions'>(this, {
            userId: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    get userName(): string {
        return this.dto.userName
    }

    set userName(name: string) {
        this.dto.userName = name
    }

    get userEmail(): string {
        return this.dto.userEmail
    }

    get userPicture(): string | undefined {
        return this.dto.userPicture
    }

    get userLanguage(): string | undefined {
        return this.dto.userLanguage
    }

    set userLanguage(newUserLanguage: string | undefined) {
        this.dto.userLanguage = newUserLanguage
    }

    get userTimezone(): string | undefined {
        return this.dto.userTimezone
    }

    set userTimezone(newUserTimezone: string | undefined) {
        this.dto.userTimezone = newUserTimezone
    }

    get role(): string {
        return this.dto.role
    }

    get pending(): boolean {
        return this.dto.pending
    }

    get invitationCreatedAt(): string | undefined {
        return this.dto.invitationCreatedAt
    }

    get invitationExpiresAt(): string | undefined {
        return this.dto.invitationExpiresAt
    }

    get blocked(): boolean {
        return this.dto.blocked
    }

    get enterpriseIdp(): boolean {
        return this.dto.enterpriseIdp
    }

    get lastLoginTime(): string | undefined {
        return this.dto.lastLoginTime
    }

    get lastLoginTimeAsDate(): Date | undefined {
        return this.dto.lastLoginTime ? new Date(this.dto.lastLoginTime) : undefined
    }

    get created(): Audit {
        return this.dto.created
    }

    get createdAsDate(): Date | undefined {
        return this.dto.created.timestamp ? new Date(this.dto.created.timestamp) : undefined
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    toDto(): UserDto {
        return toJS(this.dto)
    }

    fromDto(dto: UserDto): void {
        this.dto = { ...dto, userId: this.userId }
    }

    get selfUpdateable(): SelfUserUpdate {
        return {
            userName: this.userName,
            userLanguage: this.userLanguage,
            userTimezone: this.userTimezone,
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            // calls the service update when name, language, or timezone changes
            reaction(
                () => this.selfUpdateable,
                (partial) => {
                    logger.info('update self', partial)
                    this.service.updateSelf(partial).catch((e) => logger.warn(e))
                },
            ),
        )
    }
}
