import { AddCircle, SvgIconComponent } from '@mui/icons-material'
import { Box, Divider, IconButton, lighten, Stack, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactElement, ReactNode } from 'react'
import { KBTooltip } from '../tooltip'

type ColorComposition = {
    foreground: string
    background: string
}

export type KBItemTypeCardProps = {
    identifier?: string
    name?: string
    leftIcon?: SvgIconComponent | ReactElement
    rightIcon?: SvgIconComponent | ReactElement
    children?: ReactNode
    active?: boolean
    color?: string
    typeColor?: string
    showAdd?: boolean
    onActive?: (key?: string) => void
    onAdd?: (key?: string) => void
    containerStyle?: SxProps<Theme>
    cardStyle?: SxProps<Theme>
    typeStyle?: SxProps<Theme>
    tooltipTitle?: string
}

export function KBItemTypeCard({
    identifier,
    leftIcon,
    rightIcon,
    children,
    active = false,
    name = '',
    color = '#454545',
    typeColor = '#454545',
    showAdd = true,
    onActive,
    onAdd,
    containerStyle = {},
    cardStyle = {},
    typeStyle = {},
    tooltipTitle,
}: KBItemTypeCardProps): ReactElement {
    const colors: ColorComposition = {
        foreground: active ? '#fff' : color,
        background: active ? color : '#fff',
    }

    const typeColors: ColorComposition = {
        foreground: active ? '#fff' : typeColor ?? color,
        background: active ? typeColor ?? color : '#fff',
    }

    const cardContainerStyle: SxProps<Theme> = {
        minWidth: 200,
        width: 'fit-content',
        borderRadius: '4px',
        boxShadow: 1,
        ...containerStyle,
    }

    const cardTypeStyle: SxProps<Theme> = {
        gap: 1.25,
        paddingX: 1,
        paddingY: 0.75,
        minHeight: 32,
        background: colors.background,
        ...(!active && {
            '&:hover': {
                background: lighten(colors.foreground, 0.9),
            },
        }),
        borderRadius: 'inherit',
        ...(children && {
            borderRadius: '4px 4px 0 0',
        }),
        ...cardStyle,
    }

    const typographyStyle: SxProps<Theme> = {
        ...(active && { color: typeColors.foreground }),
        typography: 'body1',
        ...typeStyle,
    }

    const btnStyle: SxProps<Theme> = {
        color: colors.foreground,
        p: 0,
    }

    return (
        <Box sx={cardContainerStyle}>
            <Stack
                sx={cardTypeStyle}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => {
                    onActive?.(identifier)
                }}
                // FIXME: this should be moved higher in the component hierarchy; perhaps BaseItemTypeNode
                data-jtk-port={identifier} // used as a Port for JSPlumb
            >
                <Stack direction="row" alignItems="center" gap={1.25}>
                    {leftIcon && <Stack sx={btnStyle}>{leftIcon}</Stack>}
                    <KBTooltip title={tooltipTitle ?? ''}>
                        <Typography sx={typographyStyle} noWrap>
                            {name}
                        </Typography>
                    </KBTooltip>
                </Stack>
                <Stack direction="row" alignItems="center">
                    {rightIcon && <Stack sx={btnStyle}>{rightIcon}</Stack>}
                    {showAdd && (
                        <IconButton
                            sx={btnStyle}
                            onClick={(e) => {
                                e.stopPropagation()
                                onAdd?.(identifier)
                            }}
                        >
                            <AddCircle />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
            {children && <Divider />}
            {children}
        </Box>
    )
}
