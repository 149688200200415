import { useMemo } from 'react'
import { createTheme, Theme } from '@mui/material/styles'
import { alpha, lighten } from '@mui/material'
import { componentsOverride } from './componentsOverride'

let theme: Theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#8152EC',
            light: lighten('#8152EC', 0.9),
            outlinedHoverBackground: 'rgba(64, 33, 136, 0.04)',
            outlinedRestingBackground: 'rgba(129, 82, 236, 0.5)',
        },
        secondary: {
            main: '#37D2B6',
            light: 'rgba(82, 60, 168, 0.5)',
        },
        warning: {
            main: '#FFA65E',
        },
        text: {
            primary: '#383156',
            secondary: 'rgba(56, 49, 86, 0.7)',
            tertiary: 'rgba(56, 49, 86, 0.4)',
            disabled: alpha('#000', 0.25),
        },
        action: {
            active: alpha('#2E1E51', 0.54),
            disabled: alpha('#000', 0.12),
        },
        success: {
            main: '#5BD693',
        },
        background: {
            hover: 'rgba(0, 0, 0, 0.04)',
        },
        info: {
            main: '#A6B6F8',
        },
        error: {
            main: '#F04866',
        },
        highlight: {
            main: '#FF9330',
        },
        offWhite: {
            main: '#FAFAFA',
        },
        separation: {
            main: '#CCCCCC',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
        },
    },
    mixins: {
        toolbar: {
            minHeight: '50px',
        },
        searchbar: {
            minHeight: '54px',
        },
    },
    custom: 'custom value',
    drawer: {
        small: '300px',
        medium: '542px',
        large: '1050px',
    },
    shape: {
        borderRadius: 4,
    },
})

// customize shadow to match figma elevations
const { shadows } = theme
shadows[1] = '0px 1px 3px -1px rgba(0,0,0,0.12)'

theme = createTheme(theme, {
    components: componentsOverride(theme),
    shadows,
})

const useLightTheme = (): Theme => useMemo<Theme>(() => theme, [])

export default useLightTheme
