import type {
    Action,
    AttributeTypeDef as AttributeTypeDto,
    DetectorDef,
    EventDef,
    GenerationType,
    ItemType as ItemTypeDto,
} from '@kibsi/ks-application-types'
import {
    isEventItemType,
    isRegionAttr,
    isRelationshipAttr,
    isStaticItemType,
    isDetectedItemType,
} from '@kibsi/ks-application-types'

import logger from 'logging/logger'
import { makeAutoObservable } from 'mobx'
import { DomainStore, FromDtoDomainStore } from '../domain'
import type { FromDto, ToDto } from '../interfaces'
import type { ApplicationDef } from './application.def'
import { AttributeType } from './attribute.type'

export class ItemType implements ToDto<ItemTypeDto>, FromDto<ItemTypeDto> {
    readonly id: string
    readonly generationType: GenerationType
    private attributesStore: DomainStore<AttributeType, AttributeTypeDto>

    constructor(readonly applicationDef: ApplicationDef, private dto: ItemTypeDto) {
        this.id = dto.id
        this.generationType = dto.generationType
        this.attributesStore = new FromDtoDomainStore<AttributeTypeDto, AttributeType>(
            (_, data) => new AttributeType(this, data),
        )

        this.setAttributes(dto.attributes || [])

        makeAutoObservable(this)
    }

    get internalId(): string {
        return `ITEM_TYPE:${this.id}`
    }

    get appId(): string {
        return this.applicationDef.id
    }

    get name(): string {
        return this.dto.name
    }

    set name(value: string) {
        this.dto.name = value
    }

    get description(): string | undefined {
        return this.dto.description
    }

    set description(value: string | undefined) {
        this.dto.description = value
    }

    get detector(): DetectorDef[] {
        const { detector } = this.dto
        if (!detector) {
            return []
        }

        return [detector].flat()
    }

    set detector(detector: DetectorDef | DetectorDef[]) {
        this.dto.detector = detector
    }

    get attributes(): AttributeType[] {
        return this.attributesStore.values()
    }

    get event(): EventDef | undefined {
        return this.dto.event
    }

    get isCapturingMedia(): boolean {
        const startImage = Boolean(this.event?.captureOpts?.beginning?.saveImage)
        const startVideo = Boolean(this.event?.captureOpts?.beginning?.saveVideo)
        const endImage = Boolean(this.event?.captureOpts?.end?.saveImage)
        const endVideo = Boolean(this.event?.captureOpts?.end?.saveVideo)
        const entireVideo = Boolean(this.event?.captureOpts?.entire?.saveVideo)

        return startImage || startVideo || endImage || endVideo || endVideo || entireVideo
    }

    get eventActions(): Action[] {
        return this.event?.actions ?? []
    }

    get relationships(): AttributeType[] {
        return this.attributes?.filter(isRelationshipAttr)
    }

    get regions(): AttributeType[] {
        return this.attributes?.filter(isRegionAttr)
    }

    get siblings(): ItemType[] {
        return this.applicationDef.itemTypes.filter((i) => i.id !== this.id)
    }

    get displayAttributeId(): string | undefined {
        return this.dto.displayAttributeId
    }

    set displayAttributeId(value: string | undefined) {
        this.dto.displayAttributeId = value
    }

    get isStatic(): boolean {
        return isStaticItemType(this)
    }

    get isDetected(): boolean {
        return isDetectedItemType(this)
    }

    get isEvent(): boolean {
        return isEventItemType(this)
    }

    getAttributeById(id: string): AttributeType | undefined {
        return this.attributes.find((i) => i.id === id)
    }

    saveEventAction(action: Action): void {
        if (this.generationType !== 'event') {
            logger.warn('not event type')
            return
        }

        if (!this.dto.event) {
            logger.warn('no event')
            return
        }

        if (!this.dto.event.actions) {
            // if actions is empty
            this.dto.event.actions = [action]
        } else {
            const index = this.dto.event.actions.findIndex((a) => a.id === action.id)
            if (index > -1) {
                this.dto.event.actions[index] = action
            } else {
                this.dto.event.actions.push(action)
            }
        }
    }

    deleteEventAction(action: Action): void {
        const index = this.dto.event?.actions?.findIndex((a) => a.id === action.id)
        if (index === undefined || index < 0) {
            return
        }
        this.dto.event?.actions?.splice(index, 1)
    }

    addAttributeType(attributeType: AttributeTypeDto): AttributeType {
        logger.info('add attribute dto', attributeType)

        const attr = this.attributesStore.set(attributeType.id, attributeType)

        attr.afterCreate()

        return attr
    }

    deleteAttribute({ id, itemType }: AttributeType): void {
        if (itemType.id !== this.id) {
            throw new Error(`Attribute[${id}] does not belong to item[${this.id}`)
        }

        const attr = this.attributesStore.get(id)

        if (!attr) {
            throw new Error(`Attribute[${id}] does not exist in item[${this.id}]`)
        }

        this.attributesStore.delete(id)

        attr.afterDelete()
    }

    delete(): void {
        this.applicationDef.deleteItemType(this)
    }

    toDto(): ItemTypeDto {
        const newItemDto: ItemTypeDto = {
            ...this.dto,
            id: this.id,
            generationType: this.generationType,
            displayAttributeId: this.displayAttributeId,
            attributes: this.attributesStore.values().length
                ? this.attributesStore.values().map((attr) => attr.toDto())
                : undefined,
        }

        if (this.generationType === 'event' && this.dto.event) {
            // with event..  need to iterate through the actions
            newItemDto.event = {
                ...this.dto.event,
                actions: this.dto.event?.actions?.map((action) => action) ?? undefined,
            }
        }

        return newItemDto
    }

    fromDto(dto: ItemTypeDto): void {
        this.dto = { ...dto }

        this.setAttributes(dto.attributes ?? [])
    }

    destroy(): void {
        this.attributes.forEach((a) => {
            this.attributesStore.delete(a.id)

            // Need to delete the owning side of the relationship.
            a.deleteOwningRelationship()

            a.afterDelete()
        })
    }

    private setAttributes(attributes: AttributeTypeDto[]) {
        this.attributesStore.setAll(attributes.map((attr) => [attr.id, attr]))
    }
}
