import { inject, injectable } from 'inversify'
import { PasswordPolicy } from 'password-sheriff'
import { PasswordService } from 'service/password'
import T from '../../config/inversify.types'

@injectable()
export class PasswordStore {
    private internalPasswordPolicy?: PasswordPolicy
    constructor(@inject(T.PasswordService) private service: PasswordService) {}

    get passwordValidator(): PasswordPolicy | undefined {
        return this.internalPasswordPolicy
    }

    async loadPasswordValidator(): Promise<PasswordPolicy> {
        this.internalPasswordPolicy = await this.service.loadPasswordValidator()
        return this.internalPasswordPolicy
    }

    async getOrLoadValidator(): Promise<PasswordPolicy> {
        if (this.internalPasswordPolicy) {
            return this.internalPasswordPolicy
        }

        return this.loadPasswordValidator()
    }
}
