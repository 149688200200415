import type { Stream as StreamDto, StreamCreate } from '@kibsi/ks-camera-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { StreamService } from 'service/stream/stream.service'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'

import TYPES from '../../config/inversify.types'
import { Stream } from './stream'
import { JpegDataBuffer } from '../data-buffer'

@injectable()
export class StreamStore {
    private streams: AsyncDomainStore<Stream, StreamDto>

    constructor(@inject(TYPES.StreamService) private service: StreamService) {
        this.streams = new AsyncFromDtoDomainStore<StreamDto, Stream>(
            (id) => service.get(id),
            (_, data) => new Stream(data, service),
        )

        makeAutoObservable<StreamStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(siteId: string): Promise<Stream[]> {
        const dtos = await this.service.list(siteId, { pageSize: 100 })

        return this.streams.setAll(dtos.map((s) => [s.streamId, s]))
    }

    get list(): Stream[] {
        return this.streams.values()
    }

    getStreamsBySiteId(siteId: string): Stream[] {
        return this.list.filter((stream) => stream.siteId === siteId)
    }

    loadStream(id: string): Promise<Stream> {
        return this.streams.load(id)
    }

    getStream(id: string): Stream | undefined {
        return this.streams.get(id)
    }

    async createStream(input: StreamCreate): Promise<Stream> {
        const dto = await this.service.create(input)

        const stream = this.streams.set(dto.streamId, dto)

        return stream
    }

    async deleteStream(streamId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(streamId, cascade)
        this.streams.delete(streamId)
    }

    async loadSnapshot(id: string): Promise<JpegDataBuffer | undefined> {
        const buffer = await this.service.getSnapshot(id)
        return new JpegDataBuffer(buffer)
    }
}
