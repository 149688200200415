import { makeAutoObservable, observable, reaction } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { QueryFilter } from '@kibsi/ks-history-types'
import { Deployment } from '../deployment'
import { Filter, FilterData } from './filter'
import logger from '../../logging/logger'

export type FilterDataMap = Record<string, FilterData>
const FILTER_STORAGE_PREFIX = 'FILTER'

export class DeploymentFilter {
    private filterList = observable.map<string, Filter>()
    private reactions: IDisposer[] = []

    constructor(private deployment: Deployment) {
        makeAutoObservable<DeploymentFilter, 'reactions'>(this, {
            reactions: false,
        })

        this.load()
        this.bindReactions()
    }

    get deploymentId(): string {
        return this.deployment.deploymentId
    }

    add(filter: Filter): void {
        this.filterList.set(filter.id, filter)
    }

    delete(filter: Filter): void {
        this.filterList.delete(filter.id)
    }

    get filters(): Filter[] {
        return Array.from(this.filterList.values())
    }

    get enabledFilters(): Filter[] {
        return this.filters.filter((f) => f.enabled && f.toQuery())
    }

    get queryFilters(): QueryFilter[] {
        return this.enabledFilters.map((f) => f.toQuery()) as QueryFilter[]
    }

    private get storageKey(): string {
        return `${FILTER_STORAGE_PREFIX}:${this.deployment.deploymentId}`
    }

    private load() {
        logger.info('loading filters', this.deployment.deploymentId)
        const filterStr = window.localStorage.getItem(this.storageKey)
        if (filterStr) {
            const filters = JSON.parse(filterStr) as FilterData[]
            filters.forEach((dto) => {
                const filter = new Filter(dto, this.deployment)
                this.add(filter)
            })
        }
    }

    private bindReactions() {
        this.reactions.push(
            reaction(
                () => JSON.stringify(this.filters.map((f) => f.toDto())),
                (json) => window.localStorage.setItem(this.storageKey, json),
            ),
        )
    }
}
