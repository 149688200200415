import { FloorPlanMapping, FloorPlanStream as FloorPlanStreamDto } from '@kibsi/ks-tenant-types'
import { computed, makeAutoObservable, toJS } from 'mobx'
import { Point } from 'model/draw'
import { homographyMatrix } from 'pages/floor-plans/mapper/opencv'
import { FromDto, ToDto } from '../interfaces'

export type MappingPoint = {
    colorId: string
    point: Point
}

export class FloorPlanStream implements ToDto<FloorPlanStreamDto>, FromDto<FloorPlanStreamDto> {
    constructor(private dto: FloorPlanStreamDto) {
        makeAutoObservable(this, {
            homographyMatrix: computed.struct,
        })
    }

    get streamId(): string {
        return this.dto.streamId
    }

    set mappings(mappings: FloorPlanMapping[]) {
        this.dto.mappings = mappings
    }

    get mappings(): FloorPlanMapping[] {
        return this.dto.mappings ?? []
    }

    get planMappings(): MappingPoint[] {
        return this.mappings.map(({ colorId, plan }) => ({
            colorId,
            point: [plan.x, plan.y],
        }))
    }

    get streamMappings(): MappingPoint[] {
        return this.mappings.map(({ colorId, stream }) => ({
            colorId,
            point: [stream.x, stream.y],
        }))
    }

    get homographyMatrix(): number[][] {
        try {
            const homography = homographyMatrix(this.streamMappings, this.planMappings)
            return [
                [homography.data64F[0], homography.data64F[1], homography.data64F[2]],
                [homography.data64F[3], homography.data64F[4], homography.data64F[5]],
                [homography.data64F[6], homography.data64F[7], homography.data64F[8]],
            ]
        } catch (e) {
            return []
        }
    }

    addMapping(mapping: FloorPlanMapping): void {
        this.mappings = [...this.mappings, mapping]
    }

    deleteMapping(colorId: string): void {
        this.mappings = this.mappings.filter((m) => m.colorId !== colorId)
    }

    updatePlanMapping(mappingPoint: MappingPoint): void {
        const mapping = this.mappings.find((m) => m.colorId === mappingPoint.colorId)
        if (mapping) {
            mapping.plan = {
                x: mappingPoint.point[0],
                y: mappingPoint.point[1],
            }
        }
    }

    updateStreamMapping(mappingPoint: MappingPoint): void {
        const mapping = this.mappings.find((m) => m.colorId === mappingPoint.colorId)
        if (mapping) {
            mapping.stream = {
                x: mappingPoint.point[0],
                y: mappingPoint.point[1],
            }
        }
    }

    toDto(): FloorPlanStreamDto {
        return {
            ...toJS(this.dto),
            homographyMatrix: this.homographyMatrix,
        }
    }

    fromDto(dto: FloorPlanStreamDto): void {
        this.dto = dto
    }
}
