import { FunctionComponent, ReactElement, SVGProps } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export type CustomSvgProps = {
    viewBox: string
    size?: string
    svg: FunctionComponent<SVGProps<SVGSVGElement>>
} & SvgIconProps
export function CustomSvg({ viewBox, size, svg: SVG, sx, ...props }: CustomSvgProps): ReactElement {
    return (
        <SvgIcon viewBox={viewBox} sx={{ fontSize: size, ...sx }} {...props}>
            <SVG />
        </SvgIcon>
    )
}
