import { inject, injectable } from 'inversify'
import TYPES from 'config/inversify.types'
import { makeAutoObservable } from 'mobx'
import { Dashboard as DashboardDto, DashboardCreate } from '@kibsi/ks-tenant-types'
import type { DashboardService } from '../../service/dashboard/dashboard.service'
import { Dashboard } from './dashboard'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'

@injectable()
export class DashboardStore {
    private dashboards: AsyncDomainStore<Dashboard, DashboardDto>

    constructor(@inject(TYPES.DashboardService) private service: DashboardService) {
        this.dashboards = new AsyncFromDtoDomainStore<DashboardDto, Dashboard>(
            (id) => service.get(id),
            (_, data) => new Dashboard(data, service),
        )

        makeAutoObservable<DashboardStore, 'service'>(this, {
            service: false,
        })
    }

    async list(siteId: string): Promise<Dashboard[]> {
        const dtos = await this.service.list(siteId, { pageSize: 100 })
        return this.dashboards.setAll(dtos.map((d) => [d.dashboardId, d]))
    }

    getDashboard(id: string): Promise<Dashboard> {
        return this.dashboards.load(id)
    }

    async createDashboard(dashboard: DashboardCreate): Promise<Dashboard> {
        const dto = await this.service.create(dashboard)

        return this.dashboards.set(dto.dashboardId, dto)
    }

    async deleteDashboard(dashboardId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(dashboardId, cascade)
        this.dashboards.delete(dashboardId)
    }
}
