import { ContainerModule } from 'inversify'
import TYPES from '../../config/inversify.types'
import { HistoryServiceImpl } from './history.service.impl'
import { HistoryStore } from '../../store/history/history.store'
import { SnapshotStore } from '../../store/history/snapshot.store'

export const HistoryModule = new ContainerModule((bind) => {
    bind(TYPES.HistoryService).to(HistoryServiceImpl).inSingletonScope()
    bind(TYPES.HistoryStore).to(HistoryStore).inSingletonScope()
    bind(TYPES.SnapshotStore).to(SnapshotStore).inSingletonScope()
})
