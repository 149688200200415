import { useEffect, useState, ReactElement } from 'react'
import { useTheme, Input } from '@mui/material'
import { Expression } from './types'
import { SingleLineKibsiEditor, Marker } from './monaco'

const fontFamily = '"Roboto Mono"'
const lineHeight = 1.5

export interface AdvancedEditorProps {
    expression: Expression

    cursor?: number

    markers?: Marker[]

    /**
     * Callback of changes to the textual representation of the expression
     */
    onChange?: (text: string) => void

    onCursor?: (column: number) => void

    /**
     * Callback for when the user uses the up arrow (suggestions are displayed in a separate component).
     */
    // eslint-disable-next-line react/no-unused-prop-types
    onUp?: () => void

    /**
     * Callback for when the user uses the down arrow (suggestions are displayed in a separate component).
     */
    // eslint-disable-next-line react/no-unused-prop-types
    onDown?: () => void
}

export function AdvancedEditor({ expression, cursor, markers, onChange, onCursor }: AdvancedEditorProps): ReactElement {
    const { extType, plainText: expr = '' } = expression
    const [text, setText] = useState<string>(expr)
    const theme = useTheme()

    const fontSize = theme.typography.htmlFontSize
    const height = fontSize * lineHeight

    useEffect(() => {
        setText(expr)
    }, [expr])

    const onEdit = (value: string) => {
        setText(value)
        onChange?.(value)
    }

    if (extType === 'javascript') {
        return (
            <Input
                sx={{ lineHeight, fontFamily, p: 0 }}
                inputProps={{ sx: { p: 0, height: 'auto' }, 'data-testid': 'adv-expr' }}
                multiline={extType === 'javascript'}
                onChange={(e) => onEdit(e.target.value)}
                disableUnderline
                fullWidth
                value={text}
            />
        )
    }

    return (
        <SingleLineKibsiEditor
            value={text}
            markers={markers}
            cursor={cursor}
            onChange={(value) => onEdit(value ?? '')}
            onCursor={onCursor}
            height={height}
            options={{
                fontFamily,
                fontSize,
                lineHeight: height,
            }}
        />
    )
}
