import { makeAutoObservable } from 'mobx'
import type {
    AttributeRelationTypeDef,
    AttributeTypeDef as AttributeTypeDto,
    CountAttr,
    CountDef,
    Duration,
    Expression,
    MetaFlags,
    ProximityRelationshipAttr,
    RegionAttr,
    RelationshipAttr,
    StateMachineAttr,
    StateMachineDef,
} from '@kibsi/ks-application-types'
import {
    isExpressionRelationshipAttr,
    isProximityRelationshipAttr,
    isRegionAttr,
    isRelationshipAttr,
    isStateMachineAttr,
} from '@kibsi/ks-application-types'
import { generateAttrId } from 'model/app'
import log from 'logging/logger'

import { FromDto, ToDto } from '../interfaces'
import type { ItemType } from './item.type'
import type { ApplicationDef } from './application.def'
import { createRegionAttribute } from './attribute.factory'

export type AttributeValue = AttributeTypeDto['value']
export type AttributeValueType = AttributeValue['valueType']

export class AttributeType implements ToDto<AttributeTypeDto>, FromDto<AttributeTypeDto>, AttributeTypeDto {
    readonly id: string

    constructor(
        readonly itemType: ItemType, // need a way to reference the item type associated to this current attribute type
        private dto: AttributeTypeDto,
    ) {
        this.id = dto.id
        makeAutoObservable(this)
    }

    get internalId(): string {
        return `${this.itemType.internalId}:ATTR_TYPE:${this.id}`
    }

    get name(): string {
        return this.dto.name
    }

    set name(value: string) {
        this.dto.name = value
    }

    get value(): AttributeValue {
        return this.dto.value
    }

    set value(value: AttributeValue) {
        this.dto.value = value
    }

    get meta(): MetaFlags | undefined {
        return this.dto.meta
    }

    set meta(value: MetaFlags | undefined) {
        this.dto.meta = value
    }

    get conditional(): { debounce?: Duration; condition?: Expression } | undefined {
        return this.dto.conditional
    }

    get isDeletable(): boolean {
        return !this.isNonOwningRelationship()
    }

    get isEditable(): boolean {
        return !this.isNonOwningRelationship()
    }

    get appDef(): ApplicationDef {
        return this.itemType.applicationDef
    }

    isStateMachine(): this is StateMachineAttr {
        return isStateMachineAttr(this)
    }

    getStateMachineDef(): StateMachineDef {
        if (!isStateMachineAttr(this)) {
            throw Error('Not a state machine')
        }

        return this.value.stateMachine
    }

    isRelationship(): this is RelationshipAttr {
        return isRelationshipAttr(this)
    }

    isProximityRelationship(): this is ProximityRelationshipAttr {
        return isProximityRelationshipAttr(this)
    }

    isExpressionRelationship(): this is RelationshipAttr {
        return isExpressionRelationshipAttr(this)
    }

    isRegion(): this is RegionAttr {
        return isRegionAttr(this)
    }

    isCounter(): this is CountAttr {
        return this.value.valueType === 'count'
    }

    getCountDef(): CountDef {
        if (!this.isCounter()) {
            throw Error('not a Count attribute')
        }

        return this.value.count
    }

    getProximityRegion(): AttributeType | undefined {
        if (!this.isProximityRelationship()) {
            return undefined
        }

        const { proximity } = this.getRelationshipAttr().value.relation
        if (!proximity || !proximity.regionAttrId) {
            return undefined
        }
        return this.itemType.getAttributeById(proximity.regionAttrId)
    }

    toDto(): AttributeTypeDto {
        return {
            ...this.dto,
            id: this.id,
            value: this.value,
        }
    }

    fromDto(dto: AttributeTypeDto): void {
        if (dto !== this) {
            const { name, value, conditional, meta } = dto

            this.dto = {
                id: this.id,
                name,
                value: { ...value },
                conditional: conditional ? { ...conditional } : undefined,
                meta: meta ? { ...meta } : undefined,
            }
        }
    }

    update(attr: AttributeTypeDto): void {
        if (this.isOwningRelationship() && attr.value.valueType === 'relationship') {
            const existingTargetId = this.value.relation.itemTypeId
            const updatedTargetId = attr.value.relation.itemTypeId

            if (existingTargetId !== updatedTargetId) {
                log.info(`Changing relationship target from ${existingTargetId} to ${updatedTargetId}`)
                this.deleteNonOwningRelationship()
                this.fromDto(attr)
                this.addNonOwningRelationship()

                return
            }
        }

        this.fromDto(attr)
    }

    /**
     * A convenience method since deleting an attribute starts with the ItemType.
     */
    delete(): void {
        this.itemType.deleteAttribute(this)
    }

    afterCreate(): void {
        if (this.isOwningRelationship()) {
            this.addNonOwningRelationship()
        }

        if (this.isProximityRelationship()) {
            const { proximity, itemTypeId } = this.value.relation
            const { regionAttrId } = proximity

            const targetItem = this.appDef.getItemType(itemTypeId)

            if (!regionAttrId) {
                // Only need to add the region to the `static` side. If both are detected, nothing to do.
                if (this.itemType.generationType === 'static') {
                    // owning side is static
                    const region = this.itemType.addAttributeType(createRegionAttr(this.itemType))

                    proximity.regionAttrId = region.id
                } else if (targetItem?.generationType === 'static') {
                    // the non owning side is static
                    const region = targetItem.addAttributeType(createRegionAttr(targetItem))

                    proximity.regionAttrId = region.id
                }
            }
        }
    }

    afterDelete(): void {
        if (this.isOwningRelationship()) {
            /**
             * Since non owning relations are automatically added when creating a relation, they are automatically
             * deleted when the owning side is deleted.
             */
            this.deleteNonOwningRelationship()
        }
    }

    /*
     * Relationship specific methods. Ideally these should all be in some sort of subclass. Something for the future...
     */

    getRelationshipAttr(): RelationshipAttr {
        if (!isRelationshipAttr(this)) {
            throw new Error('Not a relationship')
        }

        return this
    }

    getRelationshipValue(): AttributeRelationTypeDef {
        return this.getRelationshipAttr().value
    }

    isOwningRelationship(): this is RelationshipAttr {
        return this.isRelationship() && this.value.relation.attributeId === undefined
    }

    isNonOwningRelationship(): this is RelationshipAttr {
        return this.isRelationship() && this.value.relation.attributeId !== undefined
    }

    isNonOwningSideOf(attr: AttributeType): boolean {
        if (attr.isOwningRelationship() && this.isNonOwningRelationship()) {
            const { itemTypeId, attributeId } = this.value.relation

            return itemTypeId === attr.itemType.id && attributeId === attr.id
        }

        return false
    }

    getOwningRelationship(): AttributeType | undefined {
        if (!this.isNonOwningRelationship()) {
            return undefined
        }

        const { itemTypeId, attributeId } = this.value.relation

        const owningItem = this.appDef.getItemType(itemTypeId)
        return owningItem?.getAttributeById(attributeId as string)
    }

    getNonOwningRelationship(): AttributeType | undefined {
        if (!this.isOwningRelationship()) {
            return undefined
        }

        // the non owning side
        const { itemTypeId } = this.value.relation

        // in theory there could be more if a user manually created via api. Not sure how we'd know the exact one.
        return this.itemType.siblings
            .filter((i) => i.id === itemTypeId)
            .flatMap((i) => i.attributes.filter((a) => a.isNonOwningSideOf(this)))
            .shift()
    }

    addNonOwningRelationship(): void {
        if (!this.isOwningRelationship()) {
            return
        }

        const targetId = this.value.relation.itemTypeId
        const targetItem = this.appDef.getItemType(targetId)
        const sourceItem = this.itemType
        const { name } = sourceItem

        if (!targetItem) {
            throw new Error(`item type id ${targetId} does not exist in appDef`)
        }

        log.info(`Adding non owning relation from ${sourceItem.id} to ${targetId}`)

        const attr: AttributeTypeDto = {
            id: generateAttrId(name, targetItem),
            name,
            value: {
                valueType: 'relationship',
                relation: {
                    relationType: 'bidirectional',
                    itemTypeId: sourceItem.id,
                    attributeId: this.id,
                },
            },
        }

        targetItem.addAttributeType(attr)
    }

    deleteNonOwningRelationship(): void {
        const nonOwning = this.getNonOwningRelationship()

        if (nonOwning !== undefined) {
            nonOwning.delete()
        }
    }

    deleteOwningRelationship(): void {
        this.getOwningRelationship()?.delete()
    }
}

function createRegionAttr(item: ItemType): AttributeTypeDto {
    const region = createRegionAttribute()

    return {
        ...region,
        id: generateAttrId(region.name, item),
    }
}
