import { Stream, StreamCreate, StreamUpdate, UploadUrl } from '@kibsi/ks-camera-types'
import { PaginationRequest, StreamKibsiClient } from '@kibsi/ks-client-sdk'
import { inject, injectable } from 'inversify'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { StreamService } from './stream.service'

export type StreamApi = Pick<
    InstanceType<typeof StreamKibsiClient>,
    | 'listStreamsForSite'
    | 'getStream'
    | 'createStream'
    | 'updateStream'
    | 'getSnapshot'
    | 'getLiveSnapshot'
    | 'saveSnapshot'
    | 'deleteStream'
    | 'getStreamFileUploadUrl'
>

@injectable()
export class StreamServiceImpl implements StreamService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.StreamApi) private api: StreamApi,
    ) {}

    list(siteId: string, request?: PaginationRequest<Partial<Stream>>): Promise<Stream[]> {
        return this.req.once(`streams.list`, async () => {
            const { results } = await this.api.listStreamsForSite(siteId, request)
            return results
        })
    }

    get(id: string): Promise<Stream> {
        return this.req.once(`streams.get.${id}`, () => this.api.getStream(id))
    }

    create(newStream: StreamCreate): Promise<Stream> {
        return this.api.createStream(newStream)
    }

    update(stream: StreamUpdate): Promise<Stream> {
        return this.api.updateStream(stream.streamId, stream)
    }

    delete(streamId: string, cascade?: boolean): Promise<void> {
        return this.api.deleteStream(streamId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getSnapshot(id: string): Promise<ArrayBuffer> {
        return this.req.once(`streams.get.${id}.snapshot`, () => this.api.getSnapshot(id))
    }

    getLiveSnapshot(streamId: string): Promise<ArrayBuffer> {
        return this.api.getLiveSnapshot(streamId)
    }

    async saveSnapshot(streamId: string, data: ArrayBuffer, type: string): Promise<void> {
        const blob = new Blob([data], { type })

        await this.api.saveSnapshot(streamId, blob)
    }

    getStreamFileUploadUrl(streamId: string, contentType: string): Promise<UploadUrl> {
        return this.req.once(`streams.get.${streamId}.uploadUrl`, async () =>
            this.api.getStreamFileUploadUrl(streamId, contentType),
        )
    }
}
