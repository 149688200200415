import type { User as UserDto } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import { runInAction } from 'mobx'
import type { UserService } from 'service/user'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import T from '../../config/inversify.types'
import { User } from './user'

@injectable()
export class UserStore {
    private users: AsyncDomainStore<User, UserDto>
    private listOrder: string[] = []

    private selfUserId: string | undefined

    constructor(@inject(T.UserService) private service: UserService) {
        this.users = new AsyncFromDtoDomainStore<UserDto, User>(
            (id) => service.get(id),
            (_, data) => new User(data, service),
        )
    }

    async loadList(): Promise<User[]> {
        this.listOrder.splice(0, this.listOrder.length)

        const dtos = await this.service.list()

        return runInAction(() => {
            this.listOrder.splice(0, this.listOrder.length, ...dtos.map((dto) => dto.userId))

            return dtos.map((dto) => this.users.set(dto.userId, dto))
        })
    }

    get list(): User[] {
        return this.listOrder.filter((id) => this.users.has(id)).map((id) => this.users.get(id)) as User[]
    }

    async loadSelf(): Promise<User> {
        const selfUserDto = await this.service.getSelf()
        this.selfUserId = selfUserDto.userId
        return this.users.set(this.selfUserId, selfUserDto)
    }

    clearSelfUser(): void {
        this.selfUserId = undefined
    }

    get selfUser(): User | undefined {
        if (!this.selfUserId) {
            return undefined
        }

        return this.users.get(this.selfUserId)
    }

    async getOrLoadSelfUser(): Promise<User> {
        if (this.selfUser) {
            return this.selfUser
        }

        return this.loadSelf()
    }

    async inviteUser(email: string): Promise<UserDto> {
        const dto = await this.service.create({ email })

        if (!this.listOrder.includes(dto.userId)) {
            this.listOrder.splice(0, 0, dto.userId)
        }

        return this.users.set(dto.userId, dto)
    }
}
