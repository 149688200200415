import { TenantKibsiClient } from '@kibsi/ks-client-sdk/lib/client/tenant'
import TYPES from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import { PasswordPolicy, PolicyName } from 'password-sheriff'
import { PasswordService } from './password.service'
import { PasswordValidatorFactory } from './password.validator.factory'

export type TenantApi = Pick<InstanceType<typeof TenantKibsiClient>, 'passwordPolicy'>

function isPasswordPolicy(value: string | PolicyName): value is PolicyName {
    return ['none', 'low', 'fair', 'good', 'excellent'].includes(value)
}

@injectable()
export class PasswordServiceImpl implements PasswordService {
    constructor(
        @inject(TYPES.PasswordValidatorFactory) private passwordValidatorFactory: PasswordValidatorFactory,
        @inject(TYPES.TenantApi) private api: TenantApi,
    ) {}

    async loadPasswordValidator(): Promise<PasswordPolicy> {
        const { passwordPolicy, minLength } = await this.api.passwordPolicy()

        if (isPasswordPolicy(passwordPolicy)) {
            return this.passwordValidatorFactory.createPasswordValidator(passwordPolicy, minLength)
        }

        throw Error(`password policy name ${passwordPolicy} not supported`)
    }
}
