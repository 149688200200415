import { makeAutoObservable, runInAction } from 'mobx'
import { inject, injectable } from 'inversify'
import { ARTItemState, ARTSnapshot, HistorySnapshotInterval } from '@kibsi/ks-history-types'
import { ItemType } from '@kibsi/ks-application-types'
import T from '../../config/inversify.types'
import { HistoryStore } from './history.store'
import { HistoryInterval } from '../../service/history/history.service'
import logger from '../../logging/logger'
import { ItemAttributesValues } from '../app/attribute'
import { parseSnapshotInterval } from './parseSnapshotInterval'

export type SnapshotHistory = {
    id: string
    itemState: ItemAttributesValues
    snapshot: ARTSnapshot
    itemType: ItemType
}

export type RefValue = {
    $ref: string
}

export const ITEM_CHANGES_KEY = 'changes'

@injectable()
export class SnapshotStore {
    private snapshot: ARTSnapshot = {}
    private history: SnapshotHistory[] = []

    constructor(@inject(T.HistoryStore) private historyStore: HistoryStore) {
        makeAutoObservable<SnapshotStore, 'historyStore'>(this, {
            historyStore: false,
        })
    }

    get baseSnapshot(): ARTSnapshot {
        return this.snapshot
    }

    get currentSnapshot(): ARTSnapshot {
        if (this.history.length) {
            return this.history[this.history.length - 1].snapshot
        }
        return this.snapshot
    }

    get historyCount(): number {
        return this.history.length
    }

    get hasHistory(): boolean {
        return this.history.length > 0
    }

    get lastSnapshot(): SnapshotHistory | undefined {
        if (this.history.length === 0) {
            return undefined
        }
        return this.history[this.historyCount - 1]
    }

    getItemStatesById(identifier: string | undefined, useSourceSnapshot = false): ARTItemState[] {
        if (!identifier) {
            return []
        }

        if (useSourceSnapshot) {
            return identifier ? this.snapshot[identifier] : []
        }

        return identifier ? this.currentSnapshot[identifier] : []
    }

    getItemState(itemId: string, stateId: string, useSourceSnapshot = false): ARTItemState | undefined {
        const states = this.getItemStatesById(stateId, useSourceSnapshot)
        return states?.find((is) => is.id === itemId)
    }

    async fetchSnapshot(
        deploymentId: string,
        time: number,
        interval: HistoryInterval,
    ): Promise<HistorySnapshotInterval> {
        const artSnapshot = await this.historyStore.snapshotInterval(
            deploymentId,
            new Date(time).toISOString(),
            interval,
        )
        return runInAction(() => {
            this.snapshot = parseSnapshotInterval(artSnapshot.snapshot)
            this.history = []
            return artSnapshot
        })
    }

    clearLastSnapshot(): void {
        if (this.history.length === 0) {
            return
        }
        runInAction(() => {
            this.history.pop()
        })
    }

    updateLastSnapshotWithItem(id: string, time: string): ARTItemState | undefined {
        const { lastSnapshot } = this
        if (!lastSnapshot) {
            return undefined
        }

        const itemState = lastSnapshot.snapshot[ITEM_CHANGES_KEY].find((item) => item.id === id && item.time === time)
        if (!itemState) {
            return undefined
        }

        const attributeValues = new ItemAttributesValues(itemState, lastSnapshot.itemType, time)
        const snapshotData = this.generateSnapshotDataForRelationships(attributeValues)

        return runInAction(() => {
            this.history[this.historyCount - 1] = {
                ...lastSnapshot,
                itemState: new ItemAttributesValues(itemState, lastSnapshot.itemType),
                snapshot: {
                    ...lastSnapshot.snapshot,
                    ...snapshotData,
                },
            }

            return itemState
        })
    }

    async fetchItemDetail(
        deploymentId: string,
        id: string,
        time: number,
        interval: HistoryInterval,
        itemState: ARTItemState,
        itemType: ItemType,
    ): Promise<ARTItemState[]> {
        const existing = this.history.find((hs) => hs.id === id)
        if (existing) {
            return existing.snapshot[itemType.id] || []
        }

        const itemStates = await this.historyStore.itemDetails(deploymentId, id, new Date(time).toISOString(), interval)
        const states = itemStates.map((s) => new ItemAttributesValues(s, itemType, s.time as string).state)

        // builds new snapshot based on relationship data
        return runInAction(() => {
            const attributeValues = new ItemAttributesValues(itemState, itemType, new Date(time).toISOString())
            const snapshotData = this.generateSnapshotDataForRelationships(attributeValues)
            this.history.push({
                id,
                itemType,
                itemState: attributeValues,
                snapshot: { [ITEM_CHANGES_KEY]: states, ...snapshotData },
            })
            return states
        })
    }

    private generateSnapshotDataForRelationships(itemAttributesValues: ItemAttributesValues): ARTSnapshot {
        const snapshotData: ARTSnapshot = {}
        itemAttributesValues.relationships.forEach((rel) => {
            const relValue = rel.value

            try {
                if (!Array.isArray(relValue)) {
                    return
                }

                snapshotData[rel.id] = (relValue as Array<RefValue>).map((v) => {
                    const refValue = v.$ref ?? ''
                    const [, itemTypeId, itemId] = refValue.split('/')
                    // TODO actually do something to lookup the item from the entire snapshot

                    return {
                        id: itemId ?? refValue,
                        time: itemAttributesValues.time,
                        name: itemTypeId,
                    }
                })
            } catch (e) {
                logger.error(`error parsing relationship value ${e}`)
                snapshotData[rel.id] = []
            }
        })

        return snapshotData
    }
}
