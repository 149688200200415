import type { Site as SiteDto, SiteCreate, SiteCriteria } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import { runInAction } from 'mobx'
import type { SiteService } from 'service/site'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import T from '../../config/inversify.types'
import { Site } from './site'

@injectable()
export class SiteStore {
    private sites: AsyncDomainStore<Site, SiteDto>
    private listOrder: string[] = []

    constructor(@inject(T.SiteService) private service: SiteService) {
        this.sites = new AsyncFromDtoDomainStore<SiteDto, Site>(
            (id) => service.get(id),
            (_, data) => new Site(data, service),
        )
    }

    async loadList(criteria?: SiteCriteria): Promise<Site[]> {
        this.listOrder.splice(0, this.listOrder.length)

        const dtos = await this.service.list({ pageSize: 100, criteria })

        return runInAction(() => {
            this.listOrder.splice(0, this.listOrder.length, ...dtos.map((dto) => dto.siteId))

            return dtos.map((dto) => this.sites.set(dto.siteId, dto))
        })
    }

    get list(): Site[] {
        return this.listOrder.filter((id) => this.sites.has(id)).map((id) => this.sites.get(id)) as Site[]
    }

    loadSite(id: string): Promise<Site> {
        return this.sites.load(id)
    }

    getSite(id: string): Site | undefined {
        return this.sites.get(id)
    }

    async createSite(deployment: SiteCreate): Promise<Site> {
        const dto = await this.service.create(deployment)

        return this.sites.set(dto.siteId, dto)
    }

    async deleteSite(siteId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(siteId, cascade)
        this.sites.delete(siteId)
    }
}
