import { ReactElement, useState, useRef, useCallback, ChangeEvent, useEffect } from 'react'
import { Input, InputProps, Theme, SxProps } from '@mui/material'
import { sxs } from 'utils/mui'

export type Props = Omit<InputProps, 'value'> & {
    value?: string
}

const baseSx = {
    border: '1px solid transparent',
    borderRadius: 1,
    px: 1,
    py: 0.5,
    '& input': {
        p: 0,
        height: 'auto',
    },
}

const enabledSx = {
    borderColor: 'primary.main',
}

const disabledSx = {
    '&:hover': {
        backgroundColor: 'background.hover',
    },
}

export function InlineEdit(props: Props): ReactElement {
    const { onBlur, onChange, value = '', sx = [], inputProps, error, ...rest } = props
    const { readOnly } = rest
    const input = useRef<HTMLInputElement>()
    const change = useRef<ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>()
    const commit = useRef(true)
    const [pending, setPending] = useState('')
    const [open, setOpen] = useState(false)

    const onOpen = useCallback(() => {
        if (!readOnly) {
            setPending(value)
            setOpen(true)
            commit.current = true
            input.current?.select()
        }
    }, [input, value, readOnly, setOpen, setPending])

    const onClose = useCallback(
        (e) => {
            setOpen(false)

            if (commit.current && value !== pending) {
                if (change.current !== undefined) {
                    onChange?.(change.current)
                }
            }

            onBlur?.(e)
        },
        [commit, value, pending, setOpen, onChange, onBlur],
    )

    useEffect(() => {
        const { inputRef } = props

        if (typeof inputRef === 'function') {
            inputRef(input.current)
        }
    })

    const style: SxProps<Theme> = {
        ...baseSx,
        ...(!readOnly && (open ? enabledSx : disabledSx)),
    }

    return (
        <Input
            {...rest}
            inputRef={input}
            value={open ? pending : value}
            disableUnderline={!error}
            error={error}
            onFocus={onOpen}
            onBlur={onClose}
            onChange={(e) => {
                change.current = e
                setPending(e.target.value)
            }}
            onKeyDown={(e) => {
                switch (e.key) {
                    case 'Escape':
                        commit.current = false
                        input.current?.blur()
                        break
                    case 'Enter':
                        if (!(rest.multiline && e.shiftKey)) {
                            input.current?.blur()
                        }
                        break
                    default:
                }
            }}
            sx={sxs(style, sx)}
            inputProps={{
                'data-testid': 'inline_edit_input',
                ...inputProps,
            }}
        />
    )
}
