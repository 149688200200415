import { HeatmapMetric, HeatmapOptions as HeatmapOptionsDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { FromDto, ToDto } from '../interfaces'

export type GridSize = HeatmapOptionsDto['gridSize']

export class HeatmapOptions implements FromDto<HeatmapOptionsDto>, ToDto<HeatmapOptionsDto> {
    constructor(private dto: HeatmapOptionsDto) {
        makeAutoObservable(this)
    }

    get appId(): string {
        return this.dto.appId
    }

    get floorPlanId(): string | undefined {
        return this.dto.floorPlanId
    }

    get deploymentIds(): string[] {
        return this.dto.deploymentIds || []
    }

    get itemTypes(): string[] {
        return this.dto.itemTypes || []
    }

    set itemTypes(value: string[]) {
        this.dto.itemTypes = value
    }

    get metric(): HeatmapMetric {
        return this.dto.metric
    }

    set metric(value: HeatmapMetric) {
        this.dto.metric = value
    }

    get gridSize(): GridSize | undefined {
        return this.dto.gridSize
    }

    set gridSize(value: GridSize | undefined) {
        this.dto.gridSize = value
    }

    fromDto(dto: HeatmapOptionsDto): void {
        this.dto = dto
    }

    toDto(): HeatmapOptionsDto {
        return toJS(this.dto)
    }
}
