import {
    CounterAggregateResult,
    DistinctItemsRequest,
    FloorPlanHeatmapParams,
    FloorPlanHeatmapResult,
    HeuristicQueryResult,
    HistorySnapshotInterval,
    ItemAggregateResult,
    ItemCountResult,
} from '@kibsi/ks-history-types'
import { ARTItemState, DeploymentARTSnapshot } from '@kibsi/ks-realtime-data-types'
import { AxiosRequestConfig } from 'axios'
import { KibsiClient } from 'client'
import { KibsiClientParams } from './base'

export class HistoryKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, 'history')
    }

    itemAggregates(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ItemAggregateResult> {
        return this.get(`/deployments/${deploymentId}/item-aggregates`, {
            ...requestConfig,
            params: { startTime, endTime, interval },
        })
    }

    itemCounts(
        deploymentId: string,
        startTime: string,
        endTime: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ItemCountResult> {
        return this.get(`/deployments/${deploymentId}/item-counts`, {
            ...requestConfig,
            params: { startTime, endTime },
        })
    }

    heuristicCounts(
        deploymentId: string,
        startTime: string,
        endTime: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<HeuristicQueryResult> {
        return this.get(`/deployments/${deploymentId}/heuristic-counts`, {
            ...requestConfig,
            params: { startTime, endTime },
        })
    }

    distinctItems(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: string,
        queryRequest?: DistinctItemsRequest,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ItemCountResult> {
        return this.post(`/deployments/${deploymentId}/distinct-items`, queryRequest, {
            ...requestConfig,
            params: { startTime, endTime, interval },
        })
    }

    counterAggregates(
        deploymentId: string,
        itemType: string,
        nameField: string,
        counterField: string,
        startTime: string,
        endTime: string,
        interval: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<CounterAggregateResult> {
        return this.get(`/deployments/${deploymentId}/counter-aggregates`, {
            ...requestConfig,
            params: { itemType, nameField, counterField, startTime, endTime, interval },
        })
    }

    snapshot(
        deploymentId: string,
        snapshotTime: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<DeploymentARTSnapshot> {
        return this.get(`/deployments/${deploymentId}/snapshot`, {
            ...requestConfig,
            params: { snapshotTime },
        })
    }

    snapshotInterval(
        deploymentId: string,
        startTime: string,
        endTime: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<HistorySnapshotInterval> {
        return this.get(`/deployments/${deploymentId}/snapshot-interval`, {
            ...requestConfig,
            params: { startTime, endTime },
        })
    }

    itemDetails(
        deploymentId: string,
        startTime: string,
        endTime: string,
        id: string,
        requestConfig?: AxiosRequestConfig,
    ): Promise<ARTItemState[]> {
        return this.get(`/deployments/${deploymentId}/items/${id}/details`, {
            ...requestConfig,
            params: { startTime, endTime },
        })
    }

    floorPlanHeatmap(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest: FloorPlanHeatmapParams,
        requestConfig?: AxiosRequestConfig,
    ): Promise<FloorPlanHeatmapResult> {
        return this.post(`/floor-plans/${floorPlanId}/heatmap`, queryRequest, {
            ...requestConfig,
            params: { startTime, endTime },
        })
    }
}
