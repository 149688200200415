/* eslint-disable @typescript-eslint/ban-types */

import { SxProps } from '@mui/material'

export type SxArray<T extends object = {}> = Extract<SxProps<T>, ReadonlyArray<unknown>>

export function asSxArray<T extends object = {}>(sx?: SxProps<T>): SxArray<T> {
    if (!sx) {
        return []
    }

    if (isSxArray(sx)) {
        return sx
    }

    return [sx]
}

export function isSxArray<T extends object = {}>(sx?: SxProps<T>): sx is SxArray<T> {
    return Array.isArray(sx)
}

export function sxs<T extends object = {}>(a: SxProps<T>, b?: SxProps<T>): SxArray<T> {
    return [...asSxArray(a), ...asSxArray(b)]
}
