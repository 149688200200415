import { injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'

import log from '../logging/logger'

export interface UndoRedoEvent<T> {
    action: 'undo' | 'redo'
    current: T
    previous: T
}

export interface UndoRedoListener<T> {
    (event: UndoRedoEvent<T>): void
}

@injectable()
export class UndoRedoHistory<T> {
    private stack: T[] = []
    private index = -1

    source = ''

    constructor() {
        makeAutoObservable(this)
    }

    get current(): T | undefined {
        if (this.index < 0 || this.index >= this.stack.length) {
            return undefined
        }

        return clone(this.stack[this.index])
    }

    get canUndo(): boolean {
        return this.index > 0
    }

    get canRedo(): boolean {
        return this.index < this.stack.length - 1
    }

    push(state: T): void {
        log.debug('history.push:', state)
        const detatched = clone(state)

        if (this.index === this.stack.length - 1) {
            this.index = this.stack.length
            this.stack.push(detatched)
        } else {
            this.index += 1
            this.stack.splice(this.index, this.stack.length, detatched)
        }

        this.source = ''
    }

    undo(): void {
        if (this.canUndo) {
            this.index -= 1
            this.source = 'undo'
        }
    }

    redo(): void {
        if (this.canRedo) {
            this.index += 1
            this.source = 'redo'
        }
    }

    reset(): void {
        log.info('reseting history')
        this.stack.splice(0, this.stack.length)
        this.index = -1
        this.source = ''
    }

    debug(): string {
        return JSON.stringify(this.stack, null, 2)
    }
}

function clone<T>(src: T): T {
    return JSON.parse(JSON.stringify(src)) as T
}
