import { makeAutoObservable } from 'mobx'

export class JpegDataBuffer {
    constructor(private data: ArrayBuffer) {
        makeAutoObservable(this)
    }

    get buffer(): ArrayBuffer {
        return this.data
    }

    set buffer(value: ArrayBuffer) {
        this.data = value
    }

    get base64(): string {
        return Buffer.from(this.data).toString('base64')
    }

    get dataUrl(): string {
        return `data:image/jpeg;base64,${this.base64}`
    }
}
