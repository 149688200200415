import { io, ManagerOptions } from 'socket.io-client'
import * as msgpackParser from 'socket.io-msgpack-parser'
import { VdbEndpoint } from '@kibsi/ks-deployment-types'
import { Deployment } from '../../store/deployment'
import logger from '../../logging/logger'
import { ConfigResponse, DeploymentInfo, MetadataResponse } from './video-stream.type'

const CONFIG_REQUEST_EVENT = 'config:request'
const CONFIG_RESPONSE_EVENT = 'deploymentInfo'
const METADATA_REQUEST_EVENT = 'subscribeMetadata'
const METADATA_RESPONSE_EVENT = 'deploymentMetadata'

export class VideoStreamMetadataClient {
    private readonly wsAddress: string
    private deploymentInfo?: Partial<DeploymentInfo>

    constructor(private deployment: Deployment, endpoint: VdbEndpoint) {
        this.wsAddress = `wss://${endpoint.hostname}/admin?_t=${endpoint.token}`
    }

    getDeploymentInfo(): Promise<DeploymentInfo> {
        logger.log('getting deployment info')
        return new Promise((resolve, reject) => {
            const options: Partial<ManagerOptions> = {
                transports: ['websocket'],
                parser: msgpackParser,
            }

            const socket = io(this.wsAddress, options)

            socket.on('connect', () => {
                logger.log(`socket ${socket.id} connected @ ${this.wsAddress}`)
                socket.emit(CONFIG_REQUEST_EVENT, CONFIG_RESPONSE_EVENT)
            })

            socket.on('disconnect', (reason) => {
                logger.log(`socket disconnected @ ${this.wsAddress}, reason: ${reason}`)
            })

            socket.on('connect_error', (e) => {
                logger.log(`failed to connect @ ${this.wsAddress}}`)
                reject(e)
                socket.close()
            })

            socket.on(CONFIG_RESPONSE_EVENT, (args: string) => {
                logger.log(`received deployment info - ${CONFIG_RESPONSE_EVENT}`, args)

                const { deployments } = JSON.parse(args) as ConfigResponse
                const deployment = deployments.find((d) => d.deploymentId === this.deployment.deploymentId)
                const instance = deployment?.instances[deployment.instances.length - 1]

                this.deploymentInfo = {
                    deploymentId: deployment?.deploymentId,
                    instanceId: instance,
                }

                logger.log('deployments', deployments, this.deploymentInfo)

                socket.emit(
                    METADATA_REQUEST_EVENT,
                    METADATA_RESPONSE_EVENT,
                    `${this.deploymentInfo.deploymentId}/${this.deploymentInfo.instanceId}`,
                )
            })

            socket.on(METADATA_RESPONSE_EVENT, (args) => {
                logger.log(`metadata response`, args)
                const { startTs } = args as MetadataResponse

                if (!this.deploymentInfo) {
                    reject(new Error('failed to receive deployment info'))
                    return
                }

                this.deploymentInfo.startTs = startTs

                resolve(<DeploymentInfo>this.deploymentInfo)
                socket.close()
            })
        })
    }
}
