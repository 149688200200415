import { ReactElement } from 'react'
import { alpha, Tooltip, TooltipProps, useTheme } from '@mui/material'

export type KBTooltipProps = {
    color?: string
    maxWidth?: number
} & TooltipProps

export function KBTooltip({ title, color, maxWidth = 500, children, ...props }: KBTooltipProps): ReactElement {
    const theme = useTheme()
    const tooltipColor = alpha(color ?? theme.palette.primary.main, 0.92)

    const componentsProps = {
        tooltip: {
            sx: {
                bgcolor: tooltipColor,
                '& .MuiTooltip-arrow': {
                    color: tooltipColor,
                },
                maxWidth,
            },
        },
    }

    return (
        <Tooltip componentsProps={componentsProps} title={title} {...props}>
            {children}
        </Tooltip>
    )
}
