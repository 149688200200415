import 'reflect-metadata'

import { Container, interfaces } from 'inversify'
import { EventEmitter2 } from 'eventemitter2'
import { ApiClient } from 'service/api/api-client'
import { DeploymentModule } from 'service/deployment/deployment.module'
import { EntitlementModule } from 'service/entitlement'
import { PasswordModule } from 'service/password'
import { SiteModule } from 'service/site'
import { TenantModule } from 'service/tenant'
import { StreamModule } from 'service/stream/stream.module'
import { DeviceModule } from 'service/device/device.module'
import { UserModule } from 'service/user'
import { AuthConfig } from '../auth/auth.config'
import { KibsiApiModule } from '../service/api/kibsi.api.module'
import { TimeAPI } from '../service/api/time.api'
import { ApplicationModule } from '../service/application'
import { DetectorModule } from '../service/detector/detector.module'
import { EventModule } from '../service/event'
import { HistoryModule } from '../service/history'
import { RealtimeDataModule } from '../service/realtime-data'
import { SocketModule } from '../service/socket/socket.module'
import { UiKibsiClientSdk } from '../service/ui'
import { VideoStreamModule } from '../service/video-stream/video-stream.module'
import { RequestStore } from '../store/request.store'
import { Environment } from './environment'
import TYPES from './inversify.types'
import { FilterStore } from '../store/filter'
import { ErrorStore } from '../store/error/error.store'
import { FloorPlanModule } from '../service/floor-plan/floor-plan.module'
import { DashboardModule } from '../service/dashboard/dashboard.module'

const getSdk = (c: interfaces.Container) => c.get<UiKibsiClientSdk>(TYPES.KibsiApi)
const container = new Container()

// Config
container.bind<Environment>(TYPES.Environment).to(Environment)
container.bind(TYPES.AuthConfig).to(AuthConfig).inSingletonScope()

// Api
container.bind<ApiClient>(TYPES.ApiClient).to(ApiClient).inSingletonScope()
container.bind<TimeAPI>(TYPES.TimeApi).to(TimeAPI)
container.bind(TYPES.ApplicationApi).toDynamicValue(({ container: c }) => getSdk(c).applications())
container.bind(TYPES.SiteApi).toDynamicValue(({ container: c }) => getSdk(c).sites())
container.bind(TYPES.StreamApi).toDynamicValue(({ container: c }) => getSdk(c).streams())
container.bind(TYPES.DeploymentApi).toDynamicValue(({ container: c }) => getSdk(c).deployments())
container.bind(TYPES.DetectorApi).toDynamicValue(({ container: c }) => getSdk(c).detectors())
container.bind(TYPES.UserApi).toDynamicValue(({ container: c }) => getSdk(c).users())
container.bind(TYPES.UiApi).toDynamicValue(({ container: c }) => getSdk(c).ui())
container.bind(TYPES.EventApi).toDynamicValue(({ container: c }) => getSdk(c).events())
container.bind(TYPES.RealtimeDataApi).toDynamicValue(({ container: c }) => getSdk(c).realtimeData())
container.bind(TYPES.TenantApi).toDynamicValue(({ container: c }) => getSdk(c).tenants())
container.bind(TYPES.HistoryApi).toDynamicValue(({ container: c }) => getSdk(c).history())
container.bind(TYPES.EntitlementApi).toDynamicValue(({ container: c }) => getSdk(c).entitlement())
container.bind(TYPES.DeviceApi).toDynamicValue(({ container: c }) => getSdk(c).edgeDevice())
container.bind(TYPES.FloorPlanApi).toDynamicValue(({ container: c }) => getSdk(c).floorPlan())
container.bind(TYPES.DashboardApi).toDynamicValue(({ container: c }) => getSdk(c).dashboard())

// Service
container.bind(TYPES.RequestStore).to(RequestStore)
container.bind(TYPES.AppEventBus).toConstantValue(new EventEmitter2({ delimiter: '/' }))
container.bind(TYPES.ErrorStore).to(ErrorStore).inSingletonScope()

container.load(ApplicationModule)
container.load(StreamModule)
container.load(DetectorModule)
container.load(SiteModule)
container.load(TenantModule)
container.load(DeploymentModule)
container.load(EventModule)
container.load(UserModule)
container.load(PasswordModule)
container.load(SocketModule)
container.load(RealtimeDataModule)
container.load(HistoryModule)
container.load(VideoStreamModule)
container.load(KibsiApiModule)
container.load(EntitlementModule)
container.load(DeviceModule)
container.load(FloorPlanModule)
container.load(DashboardModule)

container.bind(TYPES.FilterStore).to(FilterStore).inSingletonScope()

export default container
