import { alpha } from '@mui/material'
import { Theme } from '@mui/material/styles'

const componentsOverride = (theme: Theme) => ({
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                color: theme.palette.text.primary,
            },
            shrink: {
                color: theme.palette.text.disabled,
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'standard',
        },
        styleOverrides: {
            icon: {
                backgroundColor: alpha('#FFFFFF', 0.09),
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            dense: {
                height: '50px',
                minHeight: '50px',
                [theme.breakpoints.up('sm')]: {
                    minHeight: '50px',
                },
            },
        },
    },
    MuiBreadcrumbs: {
        styleOverrides: {
            root: {
                color: theme.palette.text.primary,
            },
        },
    },
    MuiDialogTitle: {
        defaultProps: {
            component: 'div',
        },
        styleOverrides: {
            root: {
                padding: '20px 30px 12px',
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: '0 30px',
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: '20px 30px',
            },
        },
    },
    MuiButton: {
        variants: [
            {
                props: { variant: 'link' },
                style: {
                    textTransform: 'none',
                    textDecoration: 'underline',
                    padding: '0 1px 2px 1px',
                    '&.MuiButtonBase-root:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                    },
                },
            },
        ],
    },
    MuiPickersToolbar: {
        styleOverrides: {
            root: {
                paddingBottom: 0,
                display: 'none',
            },
            content: {
                display: 'none',
            },
        },
    },
})

export { componentsOverride }
