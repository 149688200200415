import { makeAutoObservable } from 'mobx'
import { inject, injectable } from 'inversify'
import { ApplicationVersionCreate } from '@kibsi/ks-application-types'
import T from '../../config/inversify.types'
import type { ApplicationService } from '../../service'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { AppVersion, AppVersionDto } from './version'

@injectable()
export class AppVersionsStore {
    private versions: AsyncDomainStore<AppVersion, AppVersionDto>

    constructor(@inject(T.ApplicationService) private service: ApplicationService) {
        this.versions = new AsyncFromDtoDomainStore(
            (id) => service.getVersion(id),
            (_, data) => new AppVersion(data, service),
        )

        makeAutoObservable<AppVersionsStore, 'service'>(this, {
            service: false,
        })
    }

    async createVersion(appId: string, version: ApplicationVersionCreate): Promise<AppVersion> {
        const dto = await this.service.createVersion(appId, version)

        return this.versions.set(dto.versionId, dto)
    }

    async listVersions(appId: string): Promise<AppVersion[]> {
        const dtos = await this.service.listVersions(appId, {
            sort: ['versionNumber-'],
            pageSize: 100,
        })

        return this.versions.setAll(dtos.map((dto) => [dto.versionId, dto]))
    }

    async getVersion(versionId: string): Promise<AppVersion> {
        const dto = await this.service.getVersion(versionId)

        return this.versions.set(versionId, dto)
    }
}
