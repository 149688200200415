import { PaginationRequest, DeploymentKibsiClient } from '@kibsi/ks-client-sdk'
import type {
    Deployment as DeploymentDto,
    DeploymentCreate,
    DeploymentCriteria,
    DeploymentUpdate,
    LaunchApi,
    VdbEndpoint,
} from '@kibsi/ks-deployment-types'
import type { Deployment, DeploymentDetails } from '@kibsi/ks-ui-types'
import { inject, injectable } from 'inversify'
import type { UiApi } from 'service/ui'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import type { DeploymentService } from './deployment.service'

export type DeploymentApi = Pick<
    InstanceType<typeof DeploymentKibsiClient>,
    | 'create'
    | 'delete'
    | 'read'
    | 'update'
    | 'list'
    | 'launch'
    | 'getLaunch'
    | 'stop'
    | 'restart'
    | 'discardDraft'
    | 'vdbEndpoint'
>

@injectable()
export class DeploymentServiceImpl implements DeploymentService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.DeploymentApi) private api: DeploymentApi,
        @inject(TYPES.UiApi) private ui: UiApi,
    ) {}

    listFromSite(siteId: string, request: PaginationRequest<DeploymentCriteria> = {}): Promise<DeploymentDetails[]> {
        return this.req.once(`deployment.list.${siteId}`, async () => {
            const { results } = await this.ui.listDeployments({ ...request, criteria: { siteId } })
            return results
        })
    }

    listByApp(appId: string, request: PaginationRequest<DeploymentCriteria> = {}): Promise<DeploymentDetails[]> {
        return this.req.once(`deployment.list.app-${appId}`, async () => {
            const { results } = await this.ui.listDeployments({ ...request, criteria: { appId } })
            return results
        })
    }

    listByIds(ids: string[], request: PaginationRequest<DeploymentCriteria> = {}): Promise<DeploymentDetails[]> {
        return this.req.once(`deployment.list.ids-${JSON.stringify(ids)}`, async () => {
            const { results } = await this.ui.listDeployments({ ...request, criteria: { ids } })
            return results
        })
    }

    get(id: string): Promise<Deployment> {
        return this.req.once(`deployment.get.${id}`, async () => {
            const deployment = await this.ui.readDeployment(id)
            if (!deployment) {
                throw new Error('NotFound')
            }
            return deployment
        })
    }

    getDto(id: string): Promise<DeploymentDto> {
        return this.req.once(`deployment.getStatus.${id}`, async () => {
            const deployment = await this.api.read(id)
            if (!deployment) {
                throw new Error('NotFound')
            }
            return deployment
        })
    }

    async create(deployment: DeploymentCreate): Promise<DeploymentDto> {
        const result = await this.api.create(deployment)
        return result
    }

    update(deployment: DeploymentUpdate): Promise<void> {
        return this.api.update(deployment)
    }

    delete(deploymentId: string, cascade?: boolean): Promise<void> {
        return this.api.delete(deploymentId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    launch(deploymentId: string): Promise<void> {
        return this.req.once(`deployment.launch.${deploymentId}`, async () => this.api.launch(deploymentId))
    }

    getLaunch(launchId: string): Promise<LaunchApi> {
        return this.req.once(`deployment.launch.${launchId}`, async () => this.api.getLaunch(launchId))
    }

    stop(deploymentId: string): Promise<void> {
        return this.req.once(`deployment.stop.${deploymentId}`, async () => this.api.stop(deploymentId))
    }

    restart(deploymentId: string): Promise<void> {
        return this.req.once(`deployment.restart.${deploymentId}`, async () => this.api.restart(deploymentId))
    }

    async discard(deploymentId: string): Promise<Deployment> {
        await this.api.discardDraft(deploymentId)

        return this.ui.readDeployment(deploymentId)
    }

    getVdbEndpoint(deploymentId: string): Promise<VdbEndpoint> {
        return this.req.once(`deployment.vbdEndpoint.${deploymentId}`, async () => this.api.vdbEndpoint(deploymentId))
    }
}
