import { KibsiEvent } from '@kibsi/ks-event-types'
import { inject, injectable } from 'inversify'
import T from '../../config/inversify.types'
import type { EventService } from '../../service'
import type { Deployment } from '../deployment'
import { Event } from './event'
import { ItemEventMediaMetadata } from './event.media.metadata'

@injectable()
export class EventStore {
    constructor(@inject(T.EventService) private service: EventService) {}

    create(deployment: Deployment, dto: KibsiEvent): Event {
        return new Event(dto, deployment, this.service)
    }

    async getEvent(deployment: Deployment, eventId: string): Promise<Event> {
        const dto = await this.service.get(deployment.deploymentId, eventId)

        return new Event(dto, deployment, this.service)
    }

    async getMediaMetadata(deploymentId: string, eventId: string): Promise<ItemEventMediaMetadata[]> {
        const result = await this.service.getMediaMetadata(deploymentId, eventId)

        return result.map((a) => new ItemEventMediaMetadata(a))
    }
}
