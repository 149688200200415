import type { AttributeValue, CountAttribute, Primitives, TrackableAttributes } from '@kibsi/common'
// TODO: KP-1554 this resolved the tree shaking build error from common libraries
import { normalizeAttributeNameValue } from '@kibsi/common/lib/utilities/snapshot/normalizeAttributeNameValue'
import { ItemType } from '@kibsi/ks-application-types'
import { ARTItemState } from '@kibsi/ks-history-types'

export type ItemAttributeState = {
    time: string
} & ARTItemState

export class ItemAttributesValues {
    private relationshipAttributes: AttributeValue[] = []
    private countAttributes: CountAttribute[] = []
    private trackableAttributes: TrackableAttributes[] = []
    private readonly normalizedState: ItemAttributeState

    constructor(private attrs: ARTItemState, private itemType?: ItemType, time = '') {
        this.normalizedState = {
            id: attrs.id || '',
            time: (attrs.time as string) || time,
        }
        this.parseAttributes()
    }

    get time(): string {
        return this.normalizedState.time
    }

    get displayAttribute(): Primitives | undefined {
        return this.itemType?.displayAttributeId
            ? this.normalizedState[this.itemType.displayAttributeId]
            : this.itemType?.name
    }

    get relationships(): AttributeValue[] {
        return this.relationshipAttributes
    }

    get counts(): CountAttribute[] {
        return this.countAttributes
    }

    get trackables(): TrackableAttributes[] {
        return this.trackableAttributes
    }

    get tracked(): TrackableAttributes[] {
        return this.trackables.filter((a) => a.isTracked)
    }

    get untracked(): TrackableAttributes[] {
        return this.trackables.filter((a) => !a.isTracked)
    }

    get state(): ARTItemState {
        return this.normalizedState
    }

    private addRelationship(attrValue: AttributeValue): void {
        this.relationshipAttributes.push(attrValue)
        this.normalizedState[attrValue.id] = attrValue.value
    }

    private addCount(countAttr: CountAttribute): void {
        this.countAttributes.push(countAttr)
        this.normalizedState[countAttr.id] = countAttr.value
    }

    private addTrackable(trackAttr: TrackableAttributes): void {
        this.trackableAttributes.push(trackAttr)
        this.normalizedState[trackAttr.id] = trackAttr.value
    }

    private parseAttributes() {
        this.itemType?.attributes?.forEach((attr) => {
            const { id, value, meta } = attr
            const { valueType } = value
            const attrValue = this.attrs[id]

            switch (valueType) {
                case 'relationship': {
                    this.addRelationship(normalizeAttributeNameValue(attr, attrValue))
                    break
                }
                case 'region':
                case 'location':
                    break

                case 'count': {
                    this.addCount({
                        ...normalizeAttributeNameValue(attr, attrValue),
                        isWindowed: value.count.window !== 'none',
                    })
                    break
                }
                default: {
                    this.addTrackable({
                        ...normalizeAttributeNameValue(attr, attrValue),
                        isTracked: meta?.tracked || false,
                    })
                    break
                }
            }
        })
    }
}
