import {
    DashboardBasic,
    Dashboard as DashboardDto,
    DashboardUpdate,
    HeatmapOptions as HeatmapOptionsDto,
    QuickSightOptions as QuickSightOptionsDto,
} from '@kibsi/ks-tenant-types'
import logger from 'logging/logger'
import { comparer, makeAutoObservable, reaction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { DashboardService } from '../../service/dashboard/dashboard.service'
import { DashboardType } from '../../service/dashboard/types'
import { FromDto, ToDto } from '../interfaces'
import { HeatmapOptions } from './heatmap.options'
import { QuickSightOptions } from './quick-sight.options'
import { HeatmapDashboardUpdate } from './types'

export type DashboardDataUpdate = Omit<DashboardBasic, 'dashboardType'>

export class Dashboard implements ToDto<DashboardDto>, FromDto<DashboardDto> {
    private reactions: IDisposer[] = []
    readonly id: string
    options?: HeatmapOptions | QuickSightOptions

    constructor(private dto: DashboardDto, private service: DashboardService) {
        this.id = dto.dashboardId

        makeAutoObservable<Dashboard, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })

        this.setOptions(this.dto.options)
        this.bindReactions()
    }

    get name(): string {
        return this.dto.dashboardName
    }

    set name(value: string) {
        this.dto.dashboardName = value
    }

    get description(): string | undefined {
        return this.dto.dashboardDescription
    }

    set description(value: string | undefined) {
        this.dto.dashboardDescription = value
    }

    get type(): DashboardType {
        return this.dto.dashboardType
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get heatmapOptions(): HeatmapOptions | undefined {
        if (this.type === 'heatmap') {
            return this.options as HeatmapOptions
        }
        return undefined
    }

    get quickSightOptions(): QuickSightOptions | undefined {
        if (this.type === 'quickSight') {
            return this.options as QuickSightOptions
        }
        return undefined
    }

    // NOTE: We only allow updating heatmaps from the EditForm
    async update(entity: HeatmapDashboardUpdate): Promise<void> {
        const update = {
            dashboardId: this.id,
            dashboardType: this.type,
            dashboardDescription: entity.dashboardDescription,
            dashboardName: entity.dashboardName,
            options: {
                ...this.options?.toDto(),
                ...entity.options,
            } as HeatmapOptionsDto,
        } as DashboardUpdate

        const dto = await this.service.update(update)

        this.fromDto(dto)
    }

    async getUrl(): Promise<string> {
        const { url } = await this.service.getDashboardUrl(this.id)
        logger.debug('dashboard url', url)
        return url
    }

    toDto(): DashboardDto {
        return {
            ...toJS(this.dto),
            options: this.options?.toDto(),
        } as DashboardDto
    }

    fromDto(dto: DashboardDto): void {
        this.dto = { ...dto, dashboardId: this.id }
        this.setOptions(this.dto.options)
    }

    private reactionDataUpdate(): DashboardDataUpdate {
        return {
            dashboardName: this.name,
            dashboardDescription: this.description,
            options: this.options?.toDto(),
        }
    }

    private setOptions(options?: HeatmapOptionsDto | QuickSightOptionsDto): void {
        if (this.type === 'heatmap') {
            this.options = new HeatmapOptions(options as HeatmapOptionsDto)
        }

        if (this.type === 'quickSight') {
            this.options = new QuickSightOptions(options as QuickSightOptionsDto)
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.reactionDataUpdate(),
                (update) => {
                    this.service
                        .update({
                            dashboardId: this.id,
                            dashboardType: this.type,
                            ...update,
                        } as DashboardUpdate)
                        .catch(() => {})
                },
                {
                    equals: comparer.structural,
                },
            ),
        )
    }
}
