import { injectable } from 'inversify'
import { CacheLocation } from '@auth0/auth0-react'

@injectable()
class Environment {
    get authOrganization(): string {
        return process.env.REACT_APP_AUTH_ORGANIZATION || ''
    }

    get authDomain(): string {
        return process.env.REACT_APP_AUTH_DOMAIN || ''
    }

    get authClientId(): string {
        return process.env.REACT_APP_AUTH_CLIENT_ID || ''
    }

    get authRedirectUrl(): string {
        return process.env.REACT_APP_AUTH_REDIRECT_URL || ''
    }

    get authLogoutUrl(): string {
        return process.env.REACT_APP_AUTH_LOGOUT_URL || ''
    }

    get authCacheLocation(): CacheLocation {
        return (process.env.REACT_APP_AUTH_CACHE_LOCATION as CacheLocation) || 'memory'
    }

    get serviceUrl(): string {
        return process.env.REACT_APP_SERVICE_URL || ''
    }

    get enablePlayground(): boolean {
        return process.env.REACT_APP_ENABLE_PLAYGROUND?.toLocaleLowerCase() === 'true'
    }

    get userGuideUrl(): string {
        return process.env.USER_GUIDE_URL || ''
    }
}

export { Environment }
