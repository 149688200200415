/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { EventEmitter } from 'events'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { Subscription, SubscriptionCreate } from '@kibsi/ks-realtime-data-types'
import T from 'config/inversify.types'
import type { RealtimeDataService } from 'service/realtime-data'
import {
    SocketConnection,
    SocketService,
    SOCKET_CONNECTION_EVENT,
    SOCKET_MESSAGE_EVENT,
} from 'service/socket/socket.service'
import Logger from 'logging/logger'
import { Deployment } from '../deployment'

const log = Logger.getLogger('RTD')

@injectable()
export class RealtimeDataStore {
    private deployment?: Deployment

    constructor(
        @inject(T.RealtimeDataService) private rtd: RealtimeDataService,
        @inject(T.SocketService) private socket: SocketService,
        @inject(T.AppEventBus) private appbus: EventEmitter,
    ) {
        makeAutoObservable<RealtimeDataStore, 'rtd' | 'socket' | 'appbus'>(this, {
            rtd: false,
            socket: false,
            appbus: false,
        })

        this.socket.listen(SOCKET_CONNECTION_EVENT, this.onConnection.bind(this))
        this.socket.listen(SOCKET_MESSAGE_EVENT, this.onMessage.bind(this))
    }

    async start(deployment: Deployment): Promise<void> {
        log.info('RTD Starting - ', deployment.deploymentId)
        this.deployment = deployment

        // TODO: race condition for subscription events arriving before the snapshot
        await this.subscribe()
        await this.initSnapshot()
    }

    stop(): void {
        log.info('RTD Stopping - ', this.deployment?.deploymentId)
        this.socket.disconnect()
        this.deployment = undefined
    }

    private async subscribe() {
        const endpoint = await this.rtd.endpoint()

        this.socket.connect(endpoint)
    }

    private async initSnapshot() {
        if (this.deployment) {
            const snapshot = await this.rtd.deploymentSnapshot(this.deployment?.deploymentId)

            this.appbus.emit('art/snapshot', snapshot)
        }
    }

    private createSubscription(deploymentId: string, connectionId: string): Promise<Subscription> {
        const subscription: SubscriptionCreate = {
            deploymentId,
            target: {
                targetType: 'WebSocket',
                connectionId,
            },
        }

        return this.rtd.createSubscription(subscription)
    }

    private async processMessageData(data: string) {
        log.debug('WS message:', data)
        const { deployment } = this

        if (!deployment) {
            log.warn('No deployment while processing message data')
            return
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const payload = JSON.parse(data)

        // subscribe to events if we have a connectionId
        if (payload.connectionId) {
            const subscription = await this.createSubscription(deployment.deploymentId, payload.connectionId)
            log.info(`subscription created`, subscription)
            return
        }

        if ('lifecycle' in payload) {
            this.appbus.emit('art/itemstate', payload)
        }
    }

    private onConnection(connection: SocketConnection) {
        log.debug(`WS connected: ${connection.connected}`)

        if (connection.connected) {
            // get connection id
            this.socket.socket()?.send(
                JSON.stringify({
                    operation: 'getConnectionId',
                }),
            )
        }
    }

    private onMessage(message: unknown) {
        this.processMessageData(message as string).catch((err) => {
            log.error(err)
        })
    }
}
