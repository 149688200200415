import { Deployment as UiDeployment } from '@kibsi/ks-ui-types'
import { Deployment, DeploymentStatus } from '@kibsi/ks-deployment-types'

export const RUNNING_DEPLOYMENT_STATUSES: DeploymentStatus[] = ['Attempt Retry', 'In Progress', 'Pending', 'Running']
export const STOPPED_DEPLOYMENT_STATUSES: DeploymentStatus[] = ['Created', 'Error', 'Stopped']

export function toUiDeplyment(deployment: Deployment): UiDeployment {
    const { appId, versionId, siteId } = deployment

    return {
        ...deployment,
        app: { id: appId },
        version: { versionId },
        site: { siteId },
    }
}
