import { Box, Toolbar } from '@mui/material'
import { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import { MainAppBar } from './components/navigation/app-bar'

/**
 * The main ReactElement that holds all the render elements of the App
 *
 * @returns {ReactElement} <App />
 */
export function App(): ReactElement {
    return (
        <Box sx={{ height: '100%' }}>
            <MainAppBar />

            {/* All child elements of the app that uses the AppBar renders here */}
            <Toolbar />
            {/* Renders all the children of <App> see Routes.tsx */}
            <Outlet />
        </Box>
    )
}
