import { KBAppBar, KBNavItem, KBNavItemProps, KBSuspense } from '@kibsi/ui-components'
import { Stack } from '@mui/material'
import { IconAvatar } from 'components/icon'
import { useSelfUser } from 'hooks/user/useSelfUser'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { getInitals } from 'util/avatar'
import KibsiLogoLightWithName from '../../../assets/svg/kibsi-logo-with-name.svg'
import { useMainNavigations } from '../../../hooks/navigation'
import { SupportMenu } from '../../support/SupportMenu'

export const MainAppBar = observer((): ReactElement => {
    const [status, profile] = useSelfUser()
    const navigations = useMainNavigations()

    return (
        <KBAppBar
            startElement={
                <Link to="/applications" style={{ lineHeight: 0 }}>
                    <KibsiLogoLightWithName />
                </Link>
            }
            navigations={navigations}
            navItem={KBNavItem as FC<KBNavItemProps>}
        >
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ ml: 9 }}>
                {/* support */}
                <SupportMenu />

                {/* profile */}
                <KBSuspense size={30} state={status.state}>
                    <NavLink to="/profile">
                        {({ isActive }) => (
                            <IconAvatar
                                size={24}
                                src={profile?.userPicture}
                                sx={{ color: isActive ? 'primary.main' : 'text.secondary', ml: 3, mr: 0.5 }}
                            >
                                {getInitals(profile?.userName)}
                            </IconAvatar>
                        )}
                    </NavLink>
                </KBSuspense>
            </Stack>
        </KBAppBar>
    )
})
