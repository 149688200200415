import type { DeploymentCreate } from '@kibsi/ks-deployment-types'
import { Site } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import type { DeploymentService } from 'service/deployment'
import T from '../../config/inversify.types'
import { toUiDeplyment } from '../../model/deployment'
import { StreamService } from '../../service/stream'
import { parseErrorMessage } from '../../util/error'
import { AppVersion } from '../app'
import { AsyncDomainStore, AsyncDomainStoreImpl } from '../domain'
import { AnyDto, Deployment, destructure, populate, setRefs } from './deployment'

@injectable()
export class DeploymentStore {
    private deployments: AsyncDomainStore<Deployment, AnyDto>
    private listOrder: string[] = []

    constructor(
        @inject(T.DeploymentService) private service: DeploymentService,
        @inject(T.StreamService) private streamService: StreamService,
    ) {
        this.deployments = new AsyncDomainStoreImpl<AnyDto, Deployment>(
            (id) => service.get(id),
            (_, data) => this.initDeployment(data),
            populate,
        )

        makeAutoObservable<DeploymentStore, 'service'>(this, {
            service: false,
        })
    }

    async loadListFromSite(siteId: string): Promise<Deployment[]> {
        this.listOrder.splice(0, this.listOrder.length)

        const dtos = await this.service.listFromSite(siteId, { pageSize: 100 })

        return runInAction(() => {
            this.listOrder.splice(0, this.listOrder.length, ...dtos.map((dto) => dto.deploymentId))

            return dtos.map((dto) => this.deployments.set(dto.deploymentId, dto))
        })
    }

    async loadListByApp(appId: string): Promise<Deployment[]> {
        this.listOrder.splice(0, this.listOrder.length)

        const dtos = await this.service.listByApp(appId, { pageSize: 100 })

        return runInAction(() => {
            this.listOrder.splice(0, this.listOrder.length, ...dtos.map((dto) => dto.deploymentId))

            return dtos.map((dto) => this.deployments.set(dto.deploymentId, dto))
        })
    }

    async loadListByIds(deploymentIds: string[]): Promise<Deployment[]> {
        this.listOrder.splice(0, this.listOrder.length)

        const dtos = await this.service.listByIds(deploymentIds, { pageSize: 100 })

        return runInAction(() => {
            this.listOrder.splice(0, this.listOrder.length, ...dtos.map((dto) => dto.deploymentId))

            return dtos.map((dto) => this.deployments.set(dto.deploymentId, dto))
        })
    }

    loadDeployment(id: string): Promise<Deployment> {
        return this.deployments.load(id)
    }

    getDeployment(id: string): Deployment | undefined {
        return this.deployments.get(id)
    }

    async deleteDeployment(deploymentId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(deploymentId, cascade)
        this.deployments.delete(deploymentId)
    }

    async createDeployment(deployment: DeploymentCreate): Promise<Deployment> {
        const dto = await this.service.create(deployment)

        return this.deployments.set(dto.deploymentId, toUiDeplyment(dto))
    }

    async copyDeployment(deployment: Deployment, deploymentName: string): Promise<Deployment> {
        if (!deployment.isEditable()) {
            await this.loadDeployment(deployment.deploymentId)
        }

        const deploymentDescription = deployment.description ?? ''
        const { siteId, appId, versionId } = deployment

        const copy = await this.createDeployment({ deploymentName, deploymentDescription, siteId, appId, versionId })
        await this.service.update({ ...deployment.editable, deploymentName, deploymentId: copy.deploymentId })

        return copy
    }

    async createDeploymentByAppVersion(appVersion: AppVersion, site: Site): Promise<Deployment> {
        const { siteId, siteName } = site
        const appName = appVersion.versionDefinition?.name

        const createDeploymentData: DeploymentCreate = {
            appId: appVersion.appId,
            siteId,
            versionId: appVersion.versionId,
            deploymentName: `${siteName} ${appName}`,
            deploymentDescription: `Newly created Deployment for Site ${siteName} Application ${appName}`,
        }
        return this.createDeployment(createDeploymentData)
    }

    async launchDeployment(deployment: Deployment): Promise<Deployment> {
        const { deploymentId } = deployment
        try {
            await this.service.launch(deploymentId)
        } catch (err) {
            throw new Error(parseErrorMessage(err))
        }

        return this.loadDeployment(deployment.deploymentId)
    }

    async stopDeployment({ deploymentId }: Deployment): Promise<Deployment> {
        await this.service.stop(deploymentId)
        return this.loadDeployment(deploymentId)
    }

    async restartDeployment({ deploymentId }: Deployment): Promise<Deployment> {
        await this.service.restart(deploymentId)
        return this.loadDeployment(deploymentId)
    }

    get list(): Deployment[] {
        return this.listOrder
            .filter((id) => this.deployments.has(id))
            .map((id) => this.deployments.get(id)) as Deployment[]
    }

    async isLaunchEnabled(deployment: Deployment): Promise<boolean> {
        const streams = await this.streamService.list(deployment.siteId, { pageSize: 100 })
        const streamIds = streams.map((s) => s.streamId)

        if (!deployment.appId) return false
        if (!deployment.app) return false
        if (!deployment.versionId) return false
        if (deployment.streamIds?.length < 1) return false
        if (!deployment.streamIds.every((sid) => streamIds.includes(sid))) return false
        return true
    }

    private initDeployment(data: AnyDto) {
        const [dto, app, version, site] = destructure(data)
        const deployment = new Deployment(dto, this.service)

        setRefs(deployment, app, version, site)

        return deployment
    }
}
