import { Stack, SvgIcon, SxProps, Typography, TypographyProps } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { WithDataProps } from 'types'
import { asSxArray } from '../../utils/mui'

export type KBPanelBasicItemProps = {
    icon?: ReactElement
    title: ReactNode
    titleSx?: TypographyProps['sx']
    secondary?: ReactElement | string
    secondarySx?: TypographyProps['sx']
    overflow?: boolean
    sx?: SxProps
}

export function KBPanelBasicItem({
    icon,
    title,
    titleSx,
    secondary,
    secondarySx,
    overflow = true,
    sx,
    ...data
}: WithDataProps<KBPanelBasicItemProps>): ReactElement {
    const isSecondaryString = typeof secondary === 'string'

    return (
        <Stack
            direction="row"
            alignItems="top"
            justifyContent="space-between"
            spacing={2}
            sx={[{ my: 0.75 }, ...asSxArray(sx)]}
            {...data}
        >
            <Stack direction="row" alignItems="top" spacing={1.5} sx={{ ...(!overflow && { overflow: 'hidden' }) }}>
                {icon && <SvgIcon sx={{ color: 'action.active', fontSize: '20px' }}>{icon}</SvgIcon>}

                <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{
                        ...titleSx,
                        ...(!overflow && { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }),
                    }}
                >
                    {title}
                </Typography>
            </Stack>

            {isSecondaryString && (
                <Typography variant="body2" color="text.secondary" sx={secondarySx}>
                    {secondary}
                </Typography>
            )}

            {secondary && !isSecondaryString && secondary}
        </Stack>
    )
}
