import EventEmitter from 'events'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import { DeploymentARTSnapshot } from '@kibsi/ks-realtime-data-types'
import T from 'config/inversify.types'
import log from 'logging/logger'

import { Deployment } from '../deployment/deployment'
import { ArtSnapshot, ArtItemStateEvent } from './snapshot'

@injectable()
export class SnapshotManager {
    private snapshot?: ArtSnapshot

    constructor(@inject(T.AppEventBus) appbus: EventEmitter) {
        appbus.on('art/itemstate', this.onItemStateEvent.bind(this))
        appbus.on('art/snapshot', this.onSnapshot.bind(this))
        makeAutoObservable(this)
    }

    getSnapshot(): ArtSnapshot | undefined {
        return this.snapshot
    }

    setDeployment(deployment: Deployment): void {
        this.clearDeployment()
        this.snapshot = new ArtSnapshot(deployment)
    }

    clearDeployment(): void {
        this.snapshot?.close()
        this.snapshot = undefined
    }

    private onItemStateEvent(event: ArtItemStateEvent): void {
        log.info('SnapshotManager.onItemState', event)
        this.snapshot?.update(event)
    }

    private onSnapshot({ frameTime, snapshot }: DeploymentARTSnapshot): void {
        log.info('SnapshotManager.onSnapshot', { frameTime, snapshot })
        this.snapshot?.setSnapshot(frameTime, snapshot)
    }
}
