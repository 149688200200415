import { ReactElement } from 'react'
import { Avatar, AvatarProps } from '@mui/material'

export type IconAvatarProps = {
    color?: string
    size?: number
} & AvatarProps

export function IconAvatar({ size = 40, color, sx, children, ...props }: IconAvatarProps): ReactElement {
    return (
        <Avatar
            sx={{
                width: size,
                height: size,
                mr: 2,
                backgroundColor: color,
                fontSize: '0.9em',
                ...sx,
            }}
            imgProps={{ referrerPolicy: 'no-referrer' }}
            {...props}
        >
            {children}
        </Avatar>
    )
}
