import { EventKibsiClient, PaginationRequest } from '@kibsi/ks-client-sdk'
import { inject, injectable } from 'inversify'
import { EventCriteria, KibsiEvent, KibsiEventDetailed, MediaDownload, MediaMetadata } from '@kibsi/ks-event-types'
import { EventService } from './event.service'
import T from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'

export type EventApi = Pick<
    InstanceType<typeof EventKibsiClient>,
    'listEvents' | 'getEvent' | 'getEventMediaMetadata' | 'getMediaDownload'
>

@injectable()
export class EventServiceImpl implements EventService {
    constructor(@inject(T.RequestStore) private req: RequestStore, @inject(T.EventApi) private api: EventApi) {}

    list(deploymentId: string, request?: PaginationRequest<EventCriteria>): Promise<KibsiEvent[]> {
        const criteria = request?.criteria || {}
        const { active = false } = criteria

        return this.req.once(`event.list.${active}`, async () => {
            const { results } = await this.api.listEvents(deploymentId, request)
            return results
        })
    }

    get(deploymentId: string, eventId: string): Promise<KibsiEventDetailed> {
        return this.req.once(`event.get.${deploymentId}.${eventId}`, () => this.api.getEvent(deploymentId, eventId))
    }

    getMediaMetadata(deploymentId: string, eventId: string): Promise<MediaMetadata[]> {
        return this.req.once(`event.getMediaMetadata.${deploymentId}.${eventId}`, () =>
            this.api.getEventMediaMetadata(deploymentId, eventId),
        )
    }

    getMediaDownload(mediaId: string): Promise<MediaDownload> {
        return this.req.once(`event.getMediaDownload.${mediaId}`, () => this.api.getMediaDownload(mediaId))
    }
}
