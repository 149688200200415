import { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Stack,
    SvgIconProps,
    SxProps,
    Typography,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions'
import { KBOptionButton } from '../button'
import { asSxArray } from '../../utils/mui'

type ResponsiveStyleValue<T> = T | Array<T | null> | { [key: string]: T | null }

export type KBOption = {
    id: string
    title?: string
    icon?: FunctionComponent<SvgIconProps>
}

export type KBCollapsibleCardProps = {
    icon?: ReactElement
    title?: string
    startItem?: ReactElement | ReactElement[]
    options?: KBOption[]
    selectedOption?: string | string[]
    onOptionChange?: (option: string) => void
    expand?: boolean
    collapsible?: boolean
    children?: ReactNode
    onExpand?: (expand: boolean) => void
    collapsedSize?: number
    loading?: boolean
    dense?: boolean
    backgroundColor?: string
    width?: ResponsiveStyleValue<any>
    detailsSx?: SxProps
    'data-testid'?: string
}

export function KBCollapsibleCard({
    title,
    icon,
    startItem,
    options,
    children,
    selectedOption,
    expand = true,
    onOptionChange,
    onExpand,
    collapsible = true,
    collapsedSize = 0,
    loading,
    dense,
    backgroundColor,
    width,
    detailsSx,
    'data-testid': dataTestId,
}: KBCollapsibleCardProps): ReactElement {
    const [expanded, setExpanded] = useState<boolean>(collapsible ? true : expand)

    const borderRadius = '4px'

    const rootStyle = {
        width,
        backgroundColor: '#f4f4f4',
        '& .MuiAccordionSummary-root': {
            backgroundColor: 'white',
            borderRadius,
            pr: 1,
        },
        '&::before': {
            height: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderRadius: `${borderRadius} ${borderRadius} 0 0`,
        },
    }

    const summaryStyle = {
        ...(dense && { minHeight: '34px' }),
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        pointerEvents: 'none',
    }

    const detailsStyle = [{ padding: '9px', backgroundColor }, ...asSxArray(detailsSx)]

    const isActive = (id: string): boolean => {
        if (Array.isArray(selectedOption)) {
            return selectedOption.includes(id)
        }
        return selectedOption === id
    }

    const expandIcon = (
        <Stack sx={{ alignItems: 'center', height: 1, width: '30px', pointerEvents: 'auto' }}>
            {loading ? <CircularProgress size="16px" /> : <ExpandMore />}
        </Stack>
    )

    useEffect(() => {
        if (!collapsible) {
            setExpanded(true)
        }
    }, [collapsible])

    return (
        <Accordion
            sx={rootStyle}
            disableGutters
            expanded={expanded}
            onChange={(_, e) => {
                if (!collapsible) {
                    return
                }
                setExpanded(e)
                onExpand?.(e)
            }}
            TransitionProps={{ collapsedSize } as unknown as TransitionProps}
            data-testid={dataTestId}
        >
            {/* header */}
            <AccordionSummary sx={summaryStyle} expandIcon={collapsible && expandIcon}>
                <Stack sx={{ flex: 1, spacing: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ pointerEvents: 'auto' }}>
                        {icon && <Box sx={{ width: '24px', height: '24px', color: 'action.active' }}>{icon}</Box>}
                        <Typography
                            variant={dense ? 'subtitle2' : 'subtitle1'}
                            sx={{ ...(dense && { fontSize: '14px' }) }}
                        >
                            {title}
                        </Typography>
                        {startItem}
                    </Stack>

                    {/* options */}
                    <Stack direction="row" spacing={1} sx={{ mr: 1, pointerEvents: 'auto' }} alignItems="center">
                        {options?.map(({ id, title: ot, icon: Icon }) => (
                            <KBOptionButton
                                key={id}
                                active={isActive(id)}
                                data-testid={`${dataTestId}-option-${id}`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onOptionChange?.(id)
                                }}
                                icon={Icon}
                            >
                                {ot}
                            </KBOptionButton>
                        ))}
                    </Stack>
                </Stack>
            </AccordionSummary>

            {/* body */}
            <AccordionDetails sx={detailsStyle}>{children}</AccordionDetails>
        </Accordion>
    )
}
