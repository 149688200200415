import type {
    Audit,
    DetectionType,
    Detector as DetectorDto,
    DetectorMappings,
    DetectorUsage,
} from '@kibsi/ks-application-types'
import { makeAutoObservable, toJS } from 'mobx'
import { FromDto, ToDto } from '../interfaces'
import { initStatus } from '../utils'
import { DetectorService } from '../../service/detector'

export type DetectorEditable = {
    name: string
    description: string
    classes: string[]
    usage: DetectorUsage[]
    isDisabled?: boolean
    enhancerIds: string[]
    removeParentIds: string[]
    addParentIds: string[]
}

export class Detector implements ToDto<DetectorDto>, FromDto<DetectorDto>, DetectorDto {
    readonly id: string

    status = initStatus()

    constructor(private dto: DetectorDto, private service: DetectorService) {
        this.id = dto.id

        makeAutoObservable<Detector, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })
    }

    get name(): string {
        return this.dto.name
    }

    set name(name: string) {
        this.dto.name = name
    }

    get model(): string {
        return this.dto.model
    }

    get modelId(): string | undefined {
        return this.dto.modelId
    }

    get description(): string | undefined {
        return this.dto.description
    }

    set description(description: string | undefined) {
        this.dto.description = description
    }

    get limit(): number | undefined {
        return this.dto.limit
    }

    get detectionType(): DetectionType {
        return this.dto.detectionType
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get isDisabled(): boolean {
        return this.dto.isDisabled
    }

    set isDisabled(disabled: boolean) {
        this.dto.isDisabled = disabled
    }

    get isCustom(): boolean {
        return this.dto.isCustom
    }

    get tenantId(): string | undefined {
        return this.dto.tenantId
    }

    get classes(): string[] {
        return this.dto.objectsDef?.classes || []
    }

    set classes(classes: string[]) {
        this.dto.objectsDef = {
            classes,
        }
    }

    get minScore(): number | undefined {
        return this.dto.minScore
    }

    get usage(): DetectorUsage[] {
        return this.dto.usage || []
    }

    set usage(usages: DetectorUsage[]) {
        // remove duplicates
        const usageSet: Set<DetectorUsage> = new Set()
        usages.forEach((u) => usageSet.add(u))

        this.dto.usage = [...usageSet]
    }

    addEnhancers(enhancerIds: string[]): Promise<void> {
        const mappings: DetectorMappings = {
            detectorIds: enhancerIds,
        }
        return this.service.addDetectorEnhancers(this.id, mappings)
    }

    async addParents(parentIds: string[]): Promise<void> {
        if (parentIds.length > 0) {
            await Promise.all(parentIds.map((pId) => this.service.createDetectorEnhancer(pId, this.id)))
        }
    }

    async removeParents(parentIds: string[]): Promise<void> {
        if (parentIds.length > 0) {
            await Promise.all(parentIds.map((pId) => this.service.removeDetectorEnhancer(pId, this.id)))
        }
    }

    async load(): Promise<void> {
        const dto = await this.service.getDetector(this.id)
        this.fromDto(dto)
    }

    toDto(): DetectorDto {
        return toJS(this.dto)
    }

    fromDto(dto: DetectorDto): void {
        this.dto = { ...dto, id: this.id }
    }

    async update({
        name,
        description,
        classes,
        usage,
        isDisabled = false,
        enhancerIds = [],
        removeParentIds = [],
        addParentIds = [],
    }: DetectorEditable): Promise<void> {
        this.name = name
        this.description = description
        this.classes = classes
        this.isDisabled = isDisabled

        const { created, lastUpdated, ...rest } = this.dto

        const dto = await this.service.updateDetector({ ...rest, usage, modelId: this.modelId ?? '' })
        await Promise.all([
            this.service.addDetectorEnhancers(this.id, { detectorIds: enhancerIds }),
            this.addParents(addParentIds),
            this.removeParents(removeParentIds),
        ])
        this.fromDto(dto)
    }
}
