import type {
    Application,
    ApplicationCreate,
    ApplicationCriteria,
    ApplicationUpdate,
    ApplicationVersion,
    ApplicationVersionCreate,
    ApplicationVersionUpdate,
    ApplicationVersionCriteria,
    ApplicationVersionDetails,
    AppModelDefinition,
} from '@kibsi/ks-application-types'
import type { ApplicationKibsiClient, PaginationRequest } from '@kibsi/ks-client-sdk'
import { inject, injectable } from 'inversify'
import TYPES from '../../config/inversify.types'
import type { RequestStore } from '../../store/request.store'
import type { ApplicationService } from './application.service'

export type ApplicationApi = Pick<
    InstanceType<typeof ApplicationKibsiClient>,
    | 'create'
    | 'delete'
    | 'list'
    | 'read'
    | 'update'
    | 'readModelDefinition'
    | 'updateModelDefinition'
    | 'listVersions'
    | 'listApplicationVersions'
    | 'createVersion'
    | 'readVersion'
    | 'updateVersion'
>

@injectable()
export class ApplicationServiceImpl implements ApplicationService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.ApplicationApi) private api: ApplicationApi,
    ) {}

    list(request?: PaginationRequest<ApplicationCriteria>): Promise<Application[]> {
        return this.req.once('application.list', async () => {
            const { results } = await this.api.list(request)

            return results
        })
    }

    get(id: string): Promise<Application> {
        return this.req.once(`application.get.${id}`, () => this.api.read(id))
    }

    create(application: ApplicationCreate): Promise<Application> {
        return this.api.create(application)
    }

    update(application: ApplicationUpdate): Promise<Application> {
        return this.api.update(application)
    }

    delete(application: Application, cascade?: boolean): Promise<void> {
        return this.api.delete(application, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getModelDefinition(id: string): Promise<AppModelDefinition> {
        return this.req.once(`application.def.get.${id}`, () => this.api.readModelDefinition(id))
    }

    setModelDefinition(id: string, def: AppModelDefinition): Promise<void> {
        return this.api.updateModelDefinition(id, def)
    }

    listVersions(
        appId: string,
        request?: PaginationRequest<ApplicationVersionCriteria>,
    ): Promise<ApplicationVersionDetails[]> {
        return this.req.once(`application.listVersions.${appId}`, async () => {
            const { results } = await this.api.listApplicationVersions(appId, request)

            return results
        })
    }

    getVersion(id: string): Promise<ApplicationVersion> {
        return this.req.once(`application.version.get.${id}`, () => this.api.readVersion(id))
    }

    createVersion(appId: string, version: ApplicationVersionCreate): Promise<ApplicationVersion> {
        return this.api.createVersion(appId, version)
    }

    updateVersion(id: string, version: ApplicationVersionUpdate): Promise<ApplicationVersion> {
        return this.api.updateVersion(id, version)
    }
}
