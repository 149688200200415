import { useEffect } from 'react'
import { Monaco } from '@monaco-editor/react'
import { useTheme, rgbToHex } from '@mui/material'
import { kibsi } from './kibsi.monarch'

export const KibsiId = 'kibsi'

export function useKibsiLanguage(monaco: Monaco | null): void {
    const theme = useTheme()

    useEffect(() => {
        if (monaco) {
            const { palette } = theme
            const { text, highlight, primary, secondary, error } = palette

            monaco.languages.register({ id: KibsiId })
            monaco.languages.setMonarchTokensProvider(KibsiId, kibsi)

            // Note: monaco-editor can only deal with hex values.
            // According to https://github.com/microsoft/monaco-editor/issues/1815#issuecomment-583113770, it's because
            // of the way it renders the mini map that we don't use.
            monaco.editor.defineTheme(KibsiId, {
                base: 'vs',
                inherit: false,
                rules: [
                    {
                        token: 'keyword',
                        foreground: secondary.main,
                    },
                    {
                        token: 'number',
                        foreground: rgbToHex(highlight.main),
                    },
                    {
                        token: 'identifier',
                        foreground: rgbToHex(text.primary),
                    },
                    {
                        token: 'invalid',
                        foreground: rgbToHex(error.main),
                    },
                    {
                        token: 'string',
                        foreground: rgbToHex(primary.main),
                    },
                ],
                colors: {
                    'editor.foreground': rgbToHex(text.disabled),
                },
            })

            monaco.editor.setTheme(KibsiId)
        }
    }, [monaco, theme])
}
