import { injectable } from 'inversify'
import { Deployment } from '../../store/deployment'
import { VideoStreamMetadataClient } from './metadata.client'
import { FrameStore } from './frame.store'
import { VideoStreamClient } from './video-stream.client'

@injectable()
export class VideoStreamService {
    async connect(deployment: Deployment, store: FrameStore): Promise<VideoStreamClient> {
        // TODO: to be updated with authenticated api
        const endpoint = await deployment.loadVdbEndpoint()

        const metadataClient = new VideoStreamMetadataClient(deployment, endpoint)
        const deploymentInfo = await metadataClient.getDeploymentInfo()
        const streamClient = new VideoStreamClient(deploymentInfo, endpoint, store)
        await streamClient.start()
        return streamClient
    }
}
