import { Integration, IntegrationCreate, IntegrationUpdate } from '@kibsi/ks-tenant-types'
import { AxiosRequestConfig } from 'axios'
import { KibsiClient, KibsiClientParams } from './base'

const SERVICE = 'tenant'

export class IntegrationKibsiClient extends KibsiClient {
    constructor(params: KibsiClientParams) {
        super(params, SERVICE)
    }

    list(requestConfig?: AxiosRequestConfig): Promise<Integration[]> {
        return this.get(`/integrations`, requestConfig)
    }

    read(entityId: string, requestConfig?: AxiosRequestConfig): Promise<Integration> {
        return this.get(`/integrations/${entityId}`, requestConfig)
    }

    create(entity: IntegrationCreate, requestConfig?: AxiosRequestConfig): Promise<Integration> {
        return this.post('/integrations', entity, requestConfig)
    }

    update(entity: IntegrationUpdate, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.put(`/integrations/${entity.integrationId}`, entity, requestConfig)
    }

    delete(entity: Integration, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.del(`/integrations/${entity.integrationId}`, requestConfig)
    }
}
