import { action, makeObservable, observable } from 'mobx'
import { DomainStore, DomainFactory, Populator } from './store'

export class DomainStoreImpl<Dto, Domain> implements DomainStore<Domain, Dto> {
    private items = new Map<string, Domain>()

    constructor(private factory: DomainFactory<Dto, Domain>, private populate: Populator<Domain, Dto>) {
        makeObservable<this, 'items' | 'populate' | 'factory'>(this, {
            items: observable,
            set: action,
            setAll: action,
            delete: action,
            factory: false,
            populate: false,
        })
    }

    has(id: string): boolean {
        return this.items.has(id)
    }

    get(id: string): Domain | undefined {
        return this.items.get(id)
    }

    set(id: string, data: Dto): Domain {
        let item = this.items.get(id)

        if (item) {
            this.populate(item, data)
        } else {
            item = this.factory(id, data)
            this.items.set(id, item)
        }

        return item
    }

    setAll(data: [string, Dto][]): Domain[] {
        const existing = new Set(this.items.keys())
        const incomming = new Set(data.map(([id]) => id))

        Array.from(existing)
            .filter((id) => !incomming.has(id))
            .forEach((id) => {
                this.items.delete(id)
            })

        return data.map(([id, item]) => this.set(id, item))
    }

    delete(id: string): boolean {
        return this.items.delete(id)
    }

    values(): Domain[] {
        return [...this.items.values()]
    }

    clear(): void {
        this.items.clear()
    }
}
