import { ARTItemPrimitives, Primitives, SnapshotInterval } from '@kibsi/ks-history-types'

export type SnapshotPrimitive = {
    id: string
    time: string
} & ARTItemPrimitives

export function parseSnapshotInterval(snapshot: SnapshotInterval): SnapshotInterval {
    return Object.keys(snapshot).reduce((acc, key): SnapshotInterval => {
        acc[key] = snapshot[key].map(({ id, time, ...rest }) => {
            const primitive: SnapshotPrimitive = { id, time }
            Object.entries(rest).forEach(([sk, sv]) => {
                if (typeof sv === 'string') {
                    try {
                        primitive[sk] = JSON.parse(sv) as Primitives
                    } catch (e) {
                        primitive[sk] = sv
                    }
                } else {
                    primitive[sk] = sv
                }
            })
            return primitive
        })
        return acc
    }, {} as SnapshotInterval)
}
