import { Theme, PaletteColor, PaletteColorOptions } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo, ElementType } from 'react'
import useDarkTheme from './useDarkTheme'
import useLightTheme from './useLightTheme'

export * from './useDisabledOpacity'

export enum ThemeMode {
    Light = 'Light',
    Dark = 'Dark',
    Auto = 'Auto',
}

const useTheme = (option: ThemeMode): Theme => {
    // See https://mui.com/customization/default-theme/ for more information regarding theme structure
    const darkTheme = useDarkTheme()
    const lightTheme = useLightTheme()

    const prefersDarkMode: boolean = useMediaQuery('(prefers-color-scheme: dark)')
    const autoTheme = useMemo(
        () => (prefersDarkMode ? darkTheme : lightTheme),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prefersDarkMode],
    )

    switch (option) {
        case ThemeMode.Dark:
            return darkTheme
        case ThemeMode.Light:
            return lightTheme
        default:
            return autoTheme
    }
}

export default useTheme

declare module '@mui/material/styles' {
    export interface DrawerSize {
        small: string
        medium: string
        large: string
    }

    export interface Theme {
        custom?: string
        drawer: DrawerSize
    }

    export interface ThemeOptions {
        custom?: string
        drawer: DrawerSize
    }

    export interface Palette {
        highlight: PaletteColor
        offWhite: PaletteColor
        separation: PaletteColor
    }

    export interface PaletteOptions {
        highlight?: PaletteColorOptions
        offWhite?: PaletteColorOptions
        separation?: PaletteColorOptions
    }
}

declare module '@mui/material/styles/createPalette' {
    export interface PaletteColor {
        outlinedHoverBackground: string
        outlinedRestingBackground: string
    }

    export interface SimplePaletteColorOptions {
        outlinedHoverBackground?: string
        outlinedRestingBackground?: string
    }

    export interface TypeBackground {
        hover: string
    }

    export interface TypeText {
        tertiary: string
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        link: true
    }
}

declare module '@mui/material/styles/createMixins' {
    interface Mixins {
        searchbar?: {
            minHeight?: string
        }
    }
}

/**
 * NOTE: Sometimes we need the title to be an element other than h2.
 * The component prop is present but not exposed nor documented.
 * We reassure TypeScript it's really there with a declaration, which will be merged with that from @mui.
 *
 * We change the default to div for all DialogTitle instances in [componentsOverride.ts]{@link ./componentsOverride.ts}
 *
 * Discussion here –
 * https://stackoverflow.com/questions/70441304/how-do-i-make-the-mui-dialog-title-an-h1-element-so-the-modal-is-accessible#answer-70952994
 */
declare module '@mui/material/DialogTitle' {
    export interface DialogTitleProps {
        component?: ElementType
    }
}
