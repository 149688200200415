import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { ErrorHandler } from 'types'
import { Logger } from '../types/logger'

export type KibsiClientParams = {
    basePathTemplate: string
    logger?: Logger
    errorHandler?: ErrorHandler
    axiosConfigProvider?: () => Promise<AxiosRequestConfig>
}

export const KIBSI_REQUEST_ID_HEADER = 'x-kibsi-request-id'
/**
 * Client to make API calls to Kibsi services.
 * Implement per entity type by defining the REST paths.
 */
export abstract class KibsiClient {
    private logger?: Logger
    private errorHandler?: ErrorHandler

    private axiosConfigProvider?: () => Promise<AxiosRequestConfig>

    private servicePath: string

    private baseURL: string

    /**
     * The Axios instance.
     * Use instanceWithLatestConfig() to ensure it is appropriately configured.
     */
    private instance: AxiosInstance

    constructor(params: KibsiClientParams, servicePath: string) {
        this.logger = params.logger
        this.errorHandler = params.errorHandler
        this.axiosConfigProvider = params.axiosConfigProvider

        this.servicePath = servicePath
        this.baseURL = params.basePathTemplate.replace('{service}', this.servicePath)
        this.logger?.info(`Client baseURL: ${this.baseURL}`)

        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: 10_000,
        })
    }

    private async instanceWithLatestConfig(): Promise<AxiosInstance> {
        const customConfig: AxiosRequestConfig = await (this.axiosConfigProvider
            ? this.axiosConfigProvider()
            : Promise.resolve({}))

        Object.assign(this.instance.defaults, {
            ...customConfig,
            baseURL: this.baseURL,
        })

        this.logger?.debug(`Reconfigured instance with latest config.`)
        return this.instance
    }

    protected handleError(err: AxiosError): never {
        this.errorHandler?.(err, { logger: this.logger })

        throw err
    }

    /** HTTP methods */

    protected async post<Resp = unknown, Req = unknown>(
        path: string,
        entity: Req,
        requestConfig?: AxiosRequestConfig,
    ): Promise<Resp> {
        this.logger?.info(`${this.servicePath} client POST ${path}`)
        const instance = await this.instanceWithLatestConfig()
        const result = await instance.post(path, entity, requestConfig).catch((err) => this.handleError(err))

        this.logger?.debug(`Status: ${result.status}`)
        return result.data as Resp
    }

    protected async get<Resp = unknown>(path: string, requestConfig?: AxiosRequestConfig): Promise<Resp> {
        this.logger?.info(`${this.servicePath} client GET ${path}`)
        const instance = await this.instanceWithLatestConfig()
        const result = await instance.get(path, requestConfig).catch((err) => this.handleError(err))

        this.logger?.debug(`Status: ${result.status}`)
        return result.data as Resp
    }

    protected async put<Resp = unknown, Req = unknown>(
        path: string,
        entity: Req,
        requestConfig?: AxiosRequestConfig,
    ): Promise<Resp> {
        this.logger?.info(`${this.servicePath} client PUT ${path}`)
        const instance = await this.instanceWithLatestConfig()
        const result = await instance.put(path, entity, requestConfig).catch((err) => this.handleError(err))

        this.logger?.debug(`Status: ${result.status}`)
        return result.data as Resp
    }

    protected async patch<Resp = unknown, Req = unknown>(
        path: string,
        entity: Req,
        requestConfig?: AxiosRequestConfig,
    ): Promise<Resp> {
        this.logger?.info(`${this.servicePath} client PATCH ${path}`)
        const instance = await this.instanceWithLatestConfig()
        const result = await instance.patch(path, entity, requestConfig).catch((err) => this.handleError(err))

        this.logger?.debug(`Status: ${result.status}`)
        return result.data as Resp
    }

    protected async del(path: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        this.logger?.info(`${this.servicePath} client DELETE ${path}`)
        const instance = await this.instanceWithLatestConfig()
        const result = await instance.delete(path, requestConfig).catch((err) => this.handleError(err))

        this.logger?.debug(`Status: ${result.status}`)
    }
}
