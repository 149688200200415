import type { AxiosRequestConfig as ReqConfig } from 'axios'
import type { DeploymentCriteria } from '@kibsi/ks-deployment-types'
import type { Deployment, DeploymentDetails } from '@kibsi/ks-ui-types'
import {
    KibsiClient,
    KibsiClientParams,
    PaginationRequest as Req,
    PaginationResponse as Resp,
} from '@kibsi/ks-client-sdk'

export interface UiApi {
    listDeployments(request?: Req<DeploymentCriteria>, config?: ReqConfig): Promise<Resp<DeploymentDetails>>
    readDeployment(deploymentId: string, config?: ReqConfig): Promise<Deployment>
}

export class UiKibsiClient extends KibsiClient implements UiApi {
    constructor(params: KibsiClientParams) {
        super(params, 'ui')
    }

    listDeployments(request: Req<DeploymentCriteria> = {}, config: ReqConfig = {}): Promise<Resp<DeploymentDetails>> {
        const { continuationToken, pageSize, sort, criteria } = request
        const { siteId, appId, versionId, ids } = criteria ?? {}

        return this.get(`/deployments`, {
            ...config,
            params: {
                continuationToken,
                pageSize,
                sort,
                siteId,
                appId,
                versionId,
                ids: ids?.join(','),
            },
        })
    }

    readDeployment(deploymentId: string, config: ReqConfig = {}): Promise<Deployment> {
        return this.get(`/deployments/${deploymentId}`, config)
    }
}
