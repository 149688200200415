import { ContainerModule } from 'inversify'
import { DeploymentStore, DeploymentUiConfigStore } from 'store/deployment'
import { LaunchConfigStore } from 'store/launch-config/launch-config.store'
import Types from '../../config/inversify.types'
import { DeploymentServiceImpl } from './deployment.service.impl'
import { DeploymentUiConfigServiceImpl } from './deployment.ui-config.service.impl'

export const DeploymentModule = new ContainerModule((bind) => {
    bind(Types.DeploymentService).to(DeploymentServiceImpl).inSingletonScope()
    bind(Types.DeploymentStore).to(DeploymentStore).inSingletonScope()
    bind(Types.LaunchConfigStore).to(LaunchConfigStore).inSingletonScope()
    bind(Types.DeploymentUiConfigService).to(DeploymentUiConfigServiceImpl).inSingletonScope()
    bind(Types.DeploymentUiConfigStore).to(DeploymentUiConfigStore).inSingletonScope()
})
