import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'
import { UploadUrl } from '@kibsi/ks-application-types'
import type { Audit, Stream as StreamDto, StreamMetadata, StreamResolution, StreamUpdate } from '@kibsi/ks-camera-types'
import { StreamService } from 'service/stream'
import logger from 'logging/logger'
import { FromDto, ToDto } from '../interfaces'
import { JpegDataBuffer } from '../data-buffer'

export class Stream implements ToDto<StreamDto>, FromDto<StreamDto>, StreamDto {
    readonly streamId: string

    snapshot?: JpegDataBuffer
    initialized: IPromiseBasedObservable<void> = fromPromise.resolve()

    constructor(private dto: StreamDto, private service: StreamService) {
        this.streamId = dto.streamId

        makeAutoObservable<Stream, 'service' | 'reactions'>(this, {
            streamId: false,
            service: false,
            reactions: false,
        })
    }

    get name(): string {
        return this.streamName
    }

    get streamName(): string {
        return this.dto.streamName
    }

    get description(): string | undefined {
        return this.streamDescription
    }

    get streamDescription(): string | undefined {
        return this.dto.streamDescription
    }

    get streamType(): 'Stream' | 'Upload' {
        return this.dto.streamType
    }

    get connectivity(): 'DIRECT_TO_CLOUD' | 'EDGE' {
        return this.dto.connectivity
    }

    get url(): string | undefined {
        return this.dto.url
    }

    get isUploadComplete(): boolean {
        return this.dto.isUploadComplete
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get frameConsumptionRate(): number | undefined {
        return this.dto.frameRate?.consumptionRate
    }

    get streamMetadata(): StreamMetadata | undefined {
        return this.dto.streamMetadata
    }

    get resolution(): StreamResolution | undefined {
        return this.dto.streamMetadata?.resolution
    }

    async refresh(): Promise<void> {
        this.dto = await this.service.get(this.streamId)
    }

    toDto(): StreamDto {
        return toJS(this.dto)
    }

    fromDto(dto: StreamDto): void {
        this.dto = { ...dto, streamId: this.streamId }
    }

    async update(stream: StreamUpdate): Promise<void> {
        const dto = await this.service.update({ ...stream, streamId: this.streamId })

        this.fromDto(dto)
    }

    async getSnapshot(): Promise<JpegDataBuffer | undefined> {
        if (!this.snapshot) {
            const buffer = await this.service.getSnapshot(this.streamId)
            this.setSnapshotData(buffer)
        }
        return this.snapshot
    }

    async setSnapshot(data: ArrayBuffer): Promise<void> {
        await this.service.saveSnapshot(this.streamId, data, 'image/jpeg')

        this.setSnapshotData(data)
    }

    async getLiveSnapshot(): Promise<ArrayBuffer> {
        return this.service.getLiveSnapshot(this.streamId)
    }

    getStreamFileUploadUrl(contentType: string): Promise<UploadUrl> {
        return this.service.getStreamFileUploadUrl(this.streamId, contentType)
    }

    initializeSnapshot(): void {
        // This is a background action
        this.initialized = fromPromise(
            this.setInitialSnapshot().catch((e) => {
                logger.warn('Unable to capture and save the initial stream snapshot', e)
            }),
        )
    }

    private setSnapshotData(data: ArrayBuffer) {
        if (this.snapshot) {
            this.snapshot.buffer = data
        } else {
            this.snapshot = new JpegDataBuffer(data)
        }
    }

    private async setInitialSnapshot(): Promise<void> {
        await this.waitForUploadComplete()

        const buffer = await this.getLiveSnapshot()

        const snapshot = new JpegDataBuffer(buffer)

        await this.service.saveSnapshot(this.streamId, buffer, 'image/jpeg')

        runInAction(() => {
            this.snapshot = snapshot
        })
    }

    /**
     * There is a lag between the ui completing the video upload and the s3 notification.
     * This will refresh its status until the server returns a completed upload status.
     */
    private async waitForUploadComplete(): Promise<void> {
        const startedTs = Date.now()

        while (this.streamType === 'Upload' && !this.isUploadComplete) {
            if (Date.now() - startedTs > 10_000) {
                // Don't wait around forever
                throw new Error('Upload failure')
            }

            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => {
                setTimeout(resolve, 1000)
            })

            // eslint-disable-next-line no-await-in-loop
            await this.refresh()
        }
    }
}
