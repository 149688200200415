import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { DetectorCreate, DetectorCriteria, Detector as DetectorDto } from '@kibsi/ks-application-types'
import { Detector } from './detector'
import TYPES from '../../config/inversify.types'
import type { DetectorService } from '../../service/detector'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'

@injectable()
export class DetectorStore {
    private detectors: AsyncDomainStore<Detector, DetectorDto>

    constructor(@inject(TYPES.DetectorService) private service: DetectorService) {
        this.detectors = new AsyncFromDtoDomainStore<DetectorDto, Detector>(
            (id) => service.getDetector(id),
            (_, data) => new Detector(data, service),
        )

        makeAutoObservable<DetectorStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(criteria?: DetectorCriteria): Promise<Detector[]> {
        const request = { pageSize: 100, criteria, sort: ['name'] }
        const dtos = await this.service.list(request)

        return this.setDetectors(dtos)
    }

    async createDetector(create: DetectorCreate, enhancerIds: string[], parentIds: string[]): Promise<Detector> {
        const dto = await this.service.createDetector(create)

        const detector = this.detectors.set(dto.id, dto)

        if (enhancerIds.length) {
            await detector.addEnhancers(enhancerIds)
        }

        if (parentIds) {
            await detector.addParents(parentIds)
        }

        return detector
    }

    loadDetector(detectorId: string): Promise<Detector> {
        return this.detectors.load(detectorId)
    }

    getDetector(detectorId: string): Detector | undefined {
        return this.detectors.get(detectorId)
    }

    async deleteDetector(detectorId: string, cascade?: boolean): Promise<void> {
        await this.service.deleteDetector(detectorId, cascade)
        this.detectors.delete(detectorId)
    }

    async loadDetectorEnhancers(detectorIds: string[]): Promise<Detector[]> {
        const promises = detectorIds.map((dId) =>
            this.service.listDetectorEnhancers(dId, {
                pageSize: 100,
                sort: ['name'],
            }),
        )
        const dtos = await Promise.all(promises)

        const dtoMap: Record<string, DetectorDto> = {}
        dtos.forEach((arrayDto) => {
            arrayDto.forEach((detector) => {
                dtoMap[detector.id] = detector
            })
        })

        return this.setDetectors(Object.values(dtoMap).sort((a, b) => a.name.localeCompare(b.name)))
    }

    async loadModelDetectors(modelId: string): Promise<Detector[]> {
        const dtos = await this.service.getDetectorsForModel(modelId, { pageSize: 100 })

        return this.setDetectors(dtos)
    }

    async getParentDetectors(childDetectorId: string): Promise<Detector[]> {
        const dtos = await this.service.list({ criteria: { hasEnhancer: childDetectorId } })
        return this.setDetectors(dtos)
    }

    private setDetectors(dtos: DetectorDto[]): Detector[] {
        return dtos.map((dto) => this.detectors.set(dto.id, dto))
    }
}
