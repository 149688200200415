import type {
    Application as AppDto,
    ApplicationCreate,
    ApplicationCriteria,
    AppModelDefinition as AppDefDto,
} from '@kibsi/ks-application-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import T from '../../config/inversify.types'
import type { ApplicationService } from '../../service'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { UndoRedoHistory } from '../undo.redo.history'
import { Application } from './application'
import { ApplicationDef } from './application.def'
import { AppHistory, AppHistoryItem } from './history'
import { EditableApplicationDef } from './application.def.editable'

@injectable()
export class ApplicationsStore {
    private history = new Map<string, AppHistory>()
    private apps: AsyncDomainStore<Application, AppDto>
    private defs: AsyncDomainStore<ApplicationDef, AppDefDto>

    constructor(@inject(T.ApplicationService) private service: ApplicationService) {
        this.apps = new AsyncFromDtoDomainStore<AppDto, Application>(
            (id) => service.get(id),
            (id, data) => new Application(data, service, this.getHistory(id)),
        )

        this.defs = new AsyncFromDtoDomainStore<AppDefDto, ApplicationDef>(
            (id) => service.getModelDefinition(id),
            (id, data) => new EditableApplicationDef(id, data, service, this.getHistory(id)),
        )

        makeAutoObservable<ApplicationsStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(criteria?: ApplicationCriteria): Promise<Application[]> {
        const dtos = await this.service.list({ pageSize: 100, criteria, sort: ['lastUpdated-'] })
        return runInAction(() => dtos.map((dto) => this.apps.set(dto.id, dto)))
    }

    loadApp(id: string): Promise<Application> {
        return this.apps.load(id)
    }

    loadDef(id: string): Promise<ApplicationDef> {
        return this.defs.load(id)
    }

    async createApp(app: ApplicationCreate): Promise<Application> {
        const dto = await this.service.create(app)

        return this.apps.set(dto.id, dto)
    }

    async copyApp(app: Application, name: string): Promise<Application> {
        const description = app.description ?? ''

        const newApp = await this.createApp({ name, description })
        const appDef = await this.service.getModelDefinition(app.id)
        await this.service.setModelDefinition(newApp.id, appDef)

        return newApp
    }

    private getHistory(id: string): AppHistory {
        let history = this.history.get(id)

        if (!history) {
            history = new UndoRedoHistory<AppHistoryItem>()
            this.history.set(id, history)
        }

        return history
    }

    async deleteApplication(app: AppDto, cascade?: boolean): Promise<void> {
        await this.service.delete(app, cascade)
        this.apps.delete(app.id)
    }
}
